const actions = {
	nuxtServerInit ({ commit }, { req }) {
		commit('setState', {
			key: 'isLoggedIn',
			value: !!(req.session && req.session.authorization)
		})
	},
	login (vuexCtx, data) {
		return this.$axios
			.$post('/api/pos/v2/login', data.body, {
				params: data.query
			})
			.then(async (response) => {
				if (response.status === 'success') {
					if (this.$sentry) {
						this.$sentry.configureScope((scope) => {
							scope.setUser({
								device_id: response.data.info.device.id,
								id: response.data.info.merchant.id,
								username: response.data.info.username,
								email: response.data.info.email,
								location_id: response.data.info.location.id,
								location_name: response.data.info.location.name
							})
						})
					}

					vuexCtx.commit('setState', {
						key: 'isLoggedIn',
						value: true,
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'device',
						value: response.data.info.device,
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'deviceId',
						value: response.data.info.device.id,
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'locationId',
						value: response.data.info.location.id,
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'locationName',
						value: response.data.info.location.name,
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'location',
						value: {
							...response.data.info.location,
							id: response.data.info.location.id,
							name: response.data.info.location.name,
							address: response.data.info.location.address,
							customAttributes: response.data.info.location.custom_attributes
						},
						save: true
					})
					vuexCtx.commit('setState', {
						key: 'merchant',
						value: {
							id: response.data.info.merchant.id,
							mCode: response.data.info.merchant.m_code,
							name: response.data.info.name,
							email: response.data.info.email,
							username: response.data.info.username,
							websiteUrl: response.data.info.merchant.website_url.scalar,
							logoUrl: response.data.info.merchant.logo_url,
							digitalSign: response.data.info.merchant.settings?.invoice?.digital_sign,
							digitalStamp: response.data.info.merchant.settings?.invoice?.digital_stamp,
							countryCode: response.data.info.merchant.country_code,
							languageCode: response.data.info.merchant.language_code,
							currencyCode: response.data.info.merchant.currency_code,
							businessName: response.data.info.merchant.business_name,
							brandName: response.data.info.merchant.brand_name,
							timezone: response.data.info.merchant.timezone,
							businessAddress: response.data.info.merchant.business_address,
							businessPhone: response.data.info.merchant.business_phone,
							customAttributes: response.data.info.merchant.custom_attributes,
							businessType: response.data.info.merchant.business_type.slug,
							businessTypeId: response.data.info.merchant.business_type.id,
							subscription: response.data.info.merchant.subscription,
							platforms: response.data.info.merchant.platforms
						},
						save: true
					})
					vuexCtx.dispatch('checkSubscription')
					vuexCtx.commit('setSettings', response.data.info.merchant.settings)
					vuexCtx.commit('setState', {
						key: 'loginToken',
						value: response.data.token,
						save: true
					})

					if (response.data.info.merchant.logo_url) {
						this.app.getDataURL(response.data.info.merchant.logo_url).then((dataURL) => {
							this.$bridge.setLocalStorage('merchantLogo', dataURL)
						}).catch(console.error)
					}

					if (response.data.info.merchant && response.data.info.merchant.settings && response.data.info.merchant.settings.invoice?.digital_sign) {
						this.app.getDataURL(response.data.info.merchant.settings?.invoice?.digital_sign).then((dataURL) => {
							this.$bridge.setLocalStorage('digitalSignature', dataURL)
						}).catch(console.error)
					}

					if (response.data.info.merchant.settings?.invoice?.digital_stamp) {
						this.app.getDataURL(response.data.info.merchant.settings.invoice?.digital_stamp).then((dataURL) => {
							this.$bridge.setLocalStorage('merchantdigitalStamp', dataURL)
						}).catch(console.error)
					}

					if (response.data.info.merchant && response.data.info.merchant.custom_attributes &&
						response.data.info.merchant.custom_attributes.letter_head_img) {
						this.app.getDataURL(response.data.info.merchant.custom_attributes.letter_head_img).then((dataURL) => {
							this.$bridge.setLocalStorage('merchantLetterHeadImage', dataURL)
						}).catch(console.error)
					}
				}

				if (response.data.info.merchant.subscription.slug === 'mini') {
					const date = new Date()
					let existingPaymentMethods = await this.$bridge.getPaymentMethods(response.data.info.device.id, '')

					existingPaymentMethods = (typeof existingPaymentMethods === 'string' ? JSON.parse(existingPaymentMethods) : existingPaymentMethods)
					existingPaymentMethods = existingPaymentMethods.data || existingPaymentMethods

					if (existingPaymentMethods.length === 0) {
						const paymentMethods = ['cash', 'card', 'credit', 'cod', 'split', 'other']

						paymentMethods.forEach((pm, i) => {
							const paymentMethod = {
								id: i + 1,
								merchant_id: vuexCtx.state.merchant.id,
								device_id: vuexCtx.state.deviceId,
								name: pm,
								slug: pm,
								is_active: true,
								custom_attributes: '{}',
								updated_at: date
							}

							this.$bridge.insert(
								'PaymentMethod',
								this.bridgeName === 'ANDROID' ? this.app.objToJson(paymentMethod) : paymentMethod,
								true
							)
						})
					}

					let employees = await this.$bridge.getEmployees(response.data.info.device.id, response.data.info.location.id, this.app.objToJson({
						merchant_id: response.data.info.merchant.id,
						type: 'admin'
					}))

					employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
					employees = employees.data || employees

					if (employees.length === 0) {
						const employee = {
							id: 1,
							merchant_id: response.data.info.merchant.id,
							device_id: response.data.info.device.id,
							name: response.data.info.name,
							email: response.data.info.email,
							passcode: 123456,
							passkey: 1234,
							type: 'admin',
							locations: this.app.objToJson([response.data.info.location.id]),
							is_active: true,
							updated_at: date,
							roles: this.app.objToJson([{
								id: 1,
								name: 'admin',
								isActive: true,
								isAdmin: true,
								policy: {},
								createdAt: date
							}])
						}

						this.$bridge.insert(
							'Employee',
							this.bridgeName === 'ANDROID' ? this.app.objToJson(employee) : employee,
							true
						)
					}

					let discounts = await this.$bridge.getDiscounts(response.data.info.device.id, response.data.info.location.id, '')

					discounts = (typeof discounts === 'string' ? JSON.parse(discounts) : discounts)
					discounts = discounts.data || discounts

					if (discounts.length === 0) {
						const discount = {
							id: 1,
							merchant_id: response.data.info.merchant.id,
							device_id: response.data.info.device.id,
							location_id: response.data.info.location.id,
							name: 'Discount',
							description: 'Discount',
							buy_anything: false,
							buy_condition_type: null,
							buy_condition_value: 0,
							get_discount_type: null,
							get_discount_value: 0,
							max_discount_value: 0,
							entity: 'dynamic',
							discount_items: [],
							discount_categories: [],
							customers: [],
							customer_groups: [],
							valid_from: null,
							expires_on: null,
							get_free_shipping: false,
							apply_discount_sub_total: true,
							limit: 0,
							used_discount_count: 0,
							custom_attributes: this.app.objToJson({}),
							is_automatic: false,
							is_active: true,
							updated_at: date
						}

						this.$bridge.insert(
							'Discount',
							this.bridgeName === 'ANDROID' ? this.app.objToJson(discount) : discount,
							true
						)
					}

					let charges = await this.$bridge.getCharges(response.data.info.device.id, response.data.info.location.id, '')

					charges = (typeof charges === 'string' ? JSON.parse(charges) : charges)
					charges = charges.data || charges

					if (charges.length === 0) {
						const charge = {
							id: 1,
							merchant_id: response.data.info.merchant.id,
							device_id: response.data.info.device.id,
							location_id: response.data.info.location.id,
							name: 'Charge',
							value: 0,
							type: 'fixed',
							applicable_on: 'subtotal',
							category: 'dynamic_charge',
							tax: [],
							conditions: this.app.objToJson({}),
							excluded_price_categories: [],
							custom_attributes: this.app.objToJson({}),
							is_active: true,
							is_automatic: false,
							updated_at: date
						}

						await this.$bridge.insert(
							'Charge',
							vuexCtx.state.bridgeName === 'ANDROID' ? this.app.objToJson(charge) : charge,
							true
						)
					}
				}

				return response
			})
	},
	logout (vuexCtx) {
		this.$axios.get('/api/pos/logout').then(() => {
			vuexCtx.dispatch('clearLocalStorage')
			window.location.reload()
		}).catch(() => {
			vuexCtx.dispatch('clearLocalStorage')
			window.location.reload()
		})
	},
	async clearLocalStorage () {
		const data = {}
		const preservedKeys = [
			'locale',
			'loginToken',
			'cashDrawerShift',
			'employee',
			'lastOrderDay',
			'orderCount',
			'refCode',
			'categoryView',
			'paginationSize',
			'favorites',
			'showConfirmWOPrint',
			'loopNotificationSound',
			'kitchenDisplayZoomLevel',
			'archivalFrequency',
			'printerSettings',
			'kots',
			'weighingScale',
			'tidyPay',
			'pineLabs',
			'clover',
			'keyboardShortcuts',
			'skyband',
			'forceSync',
			'isWaiterAppRunning',
			'customerOtpModal',
			'foreignCurrencyExchangeRate',
			'localSettings',
			'showItemSearchDropdown'
		]

		for (const i in preservedKeys) {
			data[preservedKeys[i]] = await this.$bridge.getLocalStorage(preservedKeys[i])
		}

		this.$bridge.clearLocalStorage()

		for (const i in preservedKeys) {
			if (data[preservedKeys[i]]) {
				this.$bridge.setLocalStorage(preservedKeys[i], data[preservedKeys[i]])
			}
		}
	},
	async getDeviceUuid (vuexCtx) {
		const uuid = await this.$bridge.getDeviceUuid()

		vuexCtx.commit('setState', {
			key: 'uuid',
			value: uuid
		})
	},
	async initData (vuexCtx) {
		vuexCtx.commit('setLocale', await this.$bridge.getLocalStorage('locale') || 'en')
		vuexCtx.commit('setState', {
			key: 'partner',
			value: vuexCtx.state.partner,
			save: true
		})
		vuexCtx.commit('setState', {
			key: 'appVersion',
			value: await this.$bridge.getVersion()
		})
		vuexCtx.commit('setState', {
			key: 'isOnline',
			value: this.$offline.state === 'up'
		})

		if (this.$sentry) {
			this.$sentry.configureScope((scope) => {
				scope.setTag('version', vuexCtx.state.appVersion)
			})
		}

		await vuexCtx.dispatch('getDeviceUuid')

		if (this.$offline.state === 'up' && vuexCtx.state.isLoggedIn === false) {
			await this.$bridge.setLocalStorage('isLoggedIn', 'false')
		}

		vuexCtx.commit('setState', {
			key: 'isLoggedIn',
			value: await this.$bridge.getLocalStorage('isLoggedIn') === 'true'
		})
		vuexCtx.commit('setState', {
			key: 'loginToken',
			value: await this.$bridge.getLocalStorage('loginToken') || null
		})
		vuexCtx.commit('setState', {
			key: 'deviceId',
			value: +(await this.$bridge.getLocalStorage('deviceId') || null)
		})
		vuexCtx.commit('setState', {
			key: 'locationId',
			value: +(await this.$bridge.getLocalStorage('locationId') || null)
		})
		vuexCtx.commit('setState', {
			key: 'locationName',
			value: await this.$bridge.getLocalStorage('locationName') || null
		})

		let syncDetails = await this.$bridge.getLocalStorage('syncDetails')
		let merchant = await this.$bridge.getLocalStorage('merchant')
		const selectedMerchant = await this.$bridge.getLocalStorage('selectedMerchant')
		let location = await this.$bridge.getLocalStorage('location')
		let settings = await this.$bridge.getLocalStorage('settings')
		let localSettings = await this.$bridge.getLocalStorage('localSettings')
		let employees = await this.$bridge.getEmployees(vuexCtx.state.deviceId, vuexCtx.state.locationId, this.app.objToJson({
			type: ['admin', 'staff'],
			offset: -1
		}))
		let employee = await this.$bridge.getLocalStorage('employee')
		const employeeShift = await this.$bridge.getLocalStorage('employeeShift')
		let cashDrawerShift = await this.$bridge.getLocalStorage('cashDrawerShift')
		const categoryView = await this.$bridge.getLocalStorage('categoryView')
		const showOrderConfirmation = await this.$bridge.getLocalStorage('showOrderConfirmation')
		const showItemSearchDropdown = await this.$bridge.getLocalStorage('showItemSearchDropdown')
		const showConfirmWOPrint = await this.$bridge.getLocalStorage('showConfirmWOPrint')
		const loopNotificationSound = await this.$bridge.getLocalStorage('loopNotificationSound')
		const kitchenDisplayZoomLevel = await this.$bridge.getLocalStorage('kitchenDisplayZoomLevel')
		const archivalFrequency = await this.$bridge.getLocalStorage('archivalFrequency')
		const favorites = await this.$bridge.getLocalStorage('favorites')
		const onHold = await this.$bridge.getLocalStorage('onHold')
		const cart = await this.$bridge.getLocalStorage('cart')
		let printerSettings = await this.$bridge.getLocalStorage('printerSettings')
		let kots = await this.$bridge.getLocalStorage('kots')
		const scanner = await this.$bridge.getLocalStorage('scanner')
		const weighingScale = await this.$bridge.getLocalStorage('weighingScale')
		const internationalBancard = await this.$bridge.getLocalStorage('internationalBancard')
		const tidyPay = await this.$bridge.getLocalStorage('tidyPay')
		const skyband = await this.$bridge.getLocalStorage('skyband')
		const pineLabs = await this.$bridge.getLocalStorage('pineLabs')
		const clover = await this.$bridge.getLocalStorage('clover')
		const keyboardShortcuts = await this.$bridge.getLocalStorage('keyboardShortcuts')
		const device = await this.$bridge.getLocalStorage('device')
		const selectedSecondaryDisplay = await this.$bridge.getLocalStorage('selectedSecondaryDisplay')
		const foreignCurrencyExchangeRate = await this.$bridge.getLocalStorage('foreignCurrencyExchangeRate')

		employees = typeof employees === 'string' ? JSON.parse(employees) : employees

		vuexCtx.commit('setState', {
			key: 'bridgeName',
			value: await this.$bridge.getName()
		})
		vuexCtx.commit('setState', {
			key: 'employees',
			value: employees.data || employees
		})
		vuexCtx.commit('setState', {
			key: 'newOnlineOrdersCount',
			value: +(await this.$bridge.getLocalStorage('newOnlineOrdersCount') || null)
		})
		vuexCtx.commit('setState', {
			key: 'paginationSize',
			value: +(await this.$bridge.getLocalStorage('paginationSize')) || 20
		})

		if (syncDetails) {
			syncDetails = JSON.parse(syncDetails)

			Object.keys(vuexCtx.state.syncDetails).forEach((sd) => {
				if (!Object.keys(syncDetails).includes(sd)) {
					syncDetails[sd] = vuexCtx.state.syncDetails[sd]
				}
			})

			vuexCtx.commit('setState', {
				key: 'syncDetails',
				value: syncDetails
			})
		}

		if (merchant) {
			merchant = JSON.parse(merchant)

			if (this.$sentry) {
				this.$sentry.configureScope((scope) => {
					scope.setUser({
						device_id: vuexCtx.state.deviceId,
						id: merchant.id,
						username: merchant.username,
						email: merchant.email,
						location_id: vuexCtx.state.locationId,
						location_name: vuexCtx.state.locationName
					})
				})
			}

			vuexCtx.commit('setState', {
				key: 'merchant',
				value: merchant
			})
			vuexCtx.dispatch('checkSubscription')
		}

		if (selectedMerchant) {
			vuexCtx.commit('setState', {
				key: 'selectedMerchant',
				value: JSON.parse(selectedMerchant)
			})
		}

		if (settings) {
			settings = JSON.parse(settings)

			if (!Object.prototype.hasOwnProperty.call(settings.general, 'print_refund_reciept')) {
				settings.general.print_refund_reciept = true
			}

			vuexCtx.commit('setSettings', settings)
		}

		if (localSettings) {
			localSettings = JSON.parse(localSettings)
			vuexCtx.commit('setLocalSettings', localSettings)
		}

		if (device) {
			vuexCtx.commit('setState', {
				key: 'device',
				value: JSON.parse(device)
			})

			if (vuexCtx.state.settings.general.enable_token_number_system && !vuexCtx.state.device.is_primary) {
				const primaryDevice = JSON.parse(await this.$bridge.getLocalStorage('primaryDevice'))

				if (primaryDevice && Object.keys(primaryDevice).length) {
					vuexCtx.commit('setState', {
						key: 'primaryDevice',
						value: primaryDevice,
						save: true
					})
				}
			}
		}

		if (selectedSecondaryDisplay && (vuexCtx.state.bridgeName === 'ANDROID' || JSON.parse(selectedSecondaryDisplay)?.id)) {
			vuexCtx.commit('setState', {
				key: 'selectedSecondaryDisplay',
				value: JSON.parse(selectedSecondaryDisplay)
			})
		}

		if (location) {
			location = JSON.parse(location)
			vuexCtx.commit('setState', {
				key: 'location',
				value: location
			})
		}

		if (employee) {
			employee = JSON.parse(employee)
			vuexCtx.commit('setState', {
				key: 'employee',
				value: employee
			})
		}

		if (employeeShift) {
			vuexCtx.commit('setState', {
				key: 'employeeShift',
				value: JSON.parse(employeeShift)
			})
		} else if (employee?.shiftId) {
			vuexCtx.commit('setState', {
				key: 'employeeShift',
				value: { id: employee.shiftId, shift_code: employee.shiftCode }
			})
		}

		if (cashDrawerShift) {
			cashDrawerShift = JSON.parse(cashDrawerShift)
			vuexCtx.commit('setState', {
				key: 'cashDrawerShift',
				value: cashDrawerShift
			})
		}

		if (categoryView) {
			vuexCtx.commit('setState', {
				key: 'categoryView',
				value: categoryView
			})
		}

		if (showOrderConfirmation) {
			vuexCtx.commit('setState', {
				key: 'showOrderConfirmation',
				value: showOrderConfirmation === 'true'
			})
		}

		if (showItemSearchDropdown) {
			vuexCtx.commit('setState', {
				key: 'showItemSearchDropdown',
				value: showItemSearchDropdown === 'true'
			})
		}

		if (showConfirmWOPrint) {
			vuexCtx.commit('setState', {
				key: 'showConfirmWOPrint',
				value: showConfirmWOPrint === 'true'
			})
		}

		if (loopNotificationSound) {
			vuexCtx.commit('setState', {
				key: 'loopNotificationSound',
				value: loopNotificationSound === 'true'
			})
		}

		vuexCtx.commit('setState', {
			key: 'kitchenDisplayZoomLevel',
			value: kitchenDisplayZoomLevel || 1
		})

		if (archivalFrequency) {
			vuexCtx.commit('setState', {
				key: 'archivalFrequency',
				value: archivalFrequency
			})
		}

		if (favorites) {
			vuexCtx.commit('setState', {
				key: 'favorites',
				value: JSON.parse(favorites)
			})
		}

		if (onHold) {
			vuexCtx.commit('setState', {
				key: 'onHold',
				value: Array.isArray(JSON.parse(onHold)) ? {} : JSON.parse(onHold)
			})
		}

		if (cart) {
			vuexCtx.commit('setState', {
				key: 'cart',
				value: JSON.parse(cart)
			})
		}

		if (printerSettings) {
			printerSettings = JSON.parse(printerSettings)

			if (!printerSettings.bufferSize) {
				printerSettings.bufferSize = 800
			}

			vuexCtx.commit('setPrinterSettings', printerSettings)
		}

		if (kots) {
			kots = JSON.parse(kots)
			kots = kots.map((kot) => {
				if (!kot.devices && kot.deviceId) {
					kot.devices = [{
						id: kot.deviceId,
						name: kot.deviceName
					}]
				}

				return kot
			})
			vuexCtx.commit('setState', {
				key: 'kots',
				value: kots
			})
		}

		if (scanner) {
			vuexCtx.commit('setState', {
				key: 'scanner',
				value: JSON.parse(scanner)
			})
		}

		if (weighingScale) {
			const weighingScaleData = JSON.parse(weighingScale)

			if (!weighingScaleData.hardware) {
				weighingScaleData.hardware = 'others'
			}

			vuexCtx.commit('setState', {
				key: 'weighingScale',
				value: weighingScaleData
			})
		}

		if (internationalBancard) {
			vuexCtx.commit('setState', {
				key: 'internationalBancard',
				value: JSON.parse(internationalBancard)
			})
		}

		if (tidyPay) {
			vuexCtx.commit('setState', {
				key: 'tidyPay',
				value: {
					...JSON.parse(tidyPay),
					showModal: false, // Remove these 3 lines after two releases
					status: 'INITIALIZING',
					code: null
				}
			})
		}

		if (skyband) {
			vuexCtx.commit('setState', {
				key: 'skyband',
				value: JSON.parse(skyband)
			})
		}

		if (pineLabs) {
			vuexCtx.commit('setState', {
				key: 'pineLabs',
				value: {
					...JSON.parse(pineLabs)
				}
			})
		}

		if (clover) {
			vuexCtx.commit('setState', {
				key: 'clover',
				value: {
					...JSON.parse(clover)
				}
			})
		}

		if (keyboardShortcuts) {
			vuexCtx.commit('setState', {
				key: 'keyboardShortcuts',
				value: JSON.parse(keyboardShortcuts)
			})
		}

		if (employee && !cashDrawerShift) {
			vuexCtx.commit('setState', {
				key: 'cashDrawer',
				value: {
					show: true,
					close: false,
					type: 'starting'
				}
			})
		}

		if (foreignCurrencyExchangeRate) {
			vuexCtx.commit('setState', {
				key: 'foreignCurrencyExchangeRate',
				value: JSON.parse(foreignCurrencyExchangeRate)
			})
		}

		if (vuexCtx.state.isLoggedIn && await this.$bridge.getLocalStorage('forceSync') === 'true') {
			this.app.syncAll({ force: true })
				.catch(console.error)
				.finally(() => {
					if (typeof window?.refreshTab === 'function') {
						window.refreshTab(document.querySelector('.main-menu .nav-link.active')?.getAttribute('data-key'))
					}

					this.$bridge.setLocalStorage('forceSync', 'false')
					vuexCtx.commit('setProgress', { value: 100 })
					setTimeout(() => {
						vuexCtx.commit('resetProgress')
					}, 1000)
				})
		}
	},
	selectItem (vuexCtx, item) {
		item = Object.assign({}, item)
		item.show = true

		for (const i in item.variations) {
			const selectedPriceCategory = vuexCtx.state.cart.priceCategory

			if (vuexCtx.getters.appVersionNumber < 3825 && item.variations[i].pricing_type === 'multi') {
				item.variations[i] = this.app.applyMultiPricing(item.variations[i], selectedPriceCategory?.id)
			}

			const cartItemVariation = this.app.filterItem(vuexCtx.state.cart.items, item.variations[i])

			if (cartItemVariation) {
				item.variations[i].price = cartItemVariation.price
				item.variations[i].quantity = cartItemVariation.quantity
			}
		}

		vuexCtx.commit('setState', {
			key: 'selectedItem',
			value: item
		})
	},
	async modifyCart (vuexCtx, { item, variation, preserveIndex = false, triggerCalculation = true }) {
		const modifiers = variation.groups ? variation.groups.filter(g => g.type === 'modifier') : []
		const cartItems = JSON.parse(this.app.objToJson(vuexCtx.state.cart.items))
		const cartItem = this.app.filterItem(cartItems, variation)
		const discountTypes = ['item', 'category']
		let itemDiscount = []
		const comboDiscount = []
		let itemDiscounts = vuexCtx.getters.discounts('item').filter((d) => {
			const discountItemIndex = d.discount_items.findIndex((i) => {
				return i.variation_id === variation.id && i.buy_condition_value && (!i.buy_batch_id || variation.batch_id === i.buy_batch_id)
			})

			return discountItemIndex !== -1
		})
		const categoryDiscounts = vuexCtx.getters.discounts('category').filter((d) => {
			d.entity = 'category'

			return d.get_discount_type !== 'cheapest' && d.discount_categories.findIndex((i) => {
				return i.category_id === item.category_id && i.buy_condition_value
			}) !== -1
		})

		itemDiscounts = itemDiscounts.concat(categoryDiscounts)

		if (variation.price > 0 && variation.quantity > 0 && variation.quantity <= 9999) {
			if (itemDiscounts.length) {
				itemDiscount = itemDiscounts.reduce((discounts, discount) => {
					discount.discount_items.forEach((di) => {
						const d = {
							...di,
							id: discount.id,
							name: discount.name,
							entity: discount.entity
						}

						if (di.variation_id === variation.id &&
							di.get_discount_quantity === 0 &&
							di.get_discount_type) {
							d.type = 'ITEM'
							discounts.push(d)
						} else {
							d.type = 'COMBO'
							comboDiscount.push(d)
						}
					})

					if (discount.discount_categories) {
						discount.discount_categories.forEach((di) => {
							const d = {
								...di,
								id: discount.id,
								name: discount.name,
								entity: discount.entity
							}

							if (di.category_id === item.category_id &&
									(!di.excluded_items ||
										(di.excluded_items &&
										!di.excluded_items.includes(variation.id)))) {
								if (di.get_discount_quantity === 0 &&
									di.get_discount_type) {
									d.type = 'CATEGORY'
									discounts.push(d)
								} else {
									d.type = 'COMBO'
									comboDiscount.push(d)
								}
							}
						})
					}

					return discounts
				}, [])
			}

			if (cartItem) {
				const index = this.app.filterItem(cartItems, variation, 'index')

				cartItem.quantity = variation.quantity
				cartItem.total_price = variation.total_price
				cartItem.groups = cartItem.groups.map((group) => {
					if (group.type === 'combo') {
						group.quantity = variation.quantity
					}

					return group
				})

				if (preserveIndex) {
					cartItems[index] = cartItem
				} else {
					cartItems.splice(0, 0, cartItems.splice(index, 1)[0])
				}
			} else {
				cartItems.unshift({
					id: variation.id,
					item_id: item.id,
					category_id: item.category_id,
					brand_id: item.brand_id,
					inventory_id: variation.inventory_id,
					batch_id: variation.batch_id || null,
					price_category_id: variation.price_category_id,
					kot_device_id: variation.kot_device_id,
					name: variation.name,
					item_name: item.name,
					alternate_name: variation.custom_attributes.alternate_language,
					sku: variation.sku,
					type: variation.type,
					barcode: variation.barcode,
					hsn: variation.custom_attributes.hsn || '',
					unit_measure_type: variation.unit_measure_type,
					mrp: parseFloat(variation.custom_attributes.mrp || 0),
					price: parseFloat(variation.price),
					quantity: variation.quantity,
					total_price: variation.total_price,
					tax: variation.tax,
					discount: [],
					item_discount: itemDiscount,
					combo_discount: comboDiscount,
					itemization_type: variation.itemization_type || 'item',
					groups: (
						variation.groups || []
					)
						.filter(g => (g.type !== 'combo'))
						.concat(variation.combo
							? variation.combo.variations.map(v => ({
								item_variation_group_id: variation.combo.id,
								group_item_variation_id: v.id,
								type: 'combo',
								price: v.price,
								kot_device_id: v.kot_device_id,
								item_variation_name: v.name,
								alternate_name: v.alternate_name,
								unit_measure_type: v.unit_measure_type,
								quantity: variation.quantity,
								modifiers
							}))
							: []
						),
					notes: '',
					custom_attributes: variation.custom_attributes
				})
			}

			// combo discount
			if (comboDiscount.length) {
				for await (const entity of discountTypes) {
					const buyItems = comboDiscount.filter(i => i.entity === entity && i.buy_condition_type && i.buy_condition_type.length !== 0)
					const getItems = comboDiscount.filter(i => i.entity === entity && i.get_discount_type && i.get_discount_type.length !== 0)

					if (buyItems.length && getItems.length) {
						let addGetItemsFlag = true
						const buyItemsData = []

						buyItems.forEach((d) => {
							const cartCategories = vuexCtx.getters.cartCategories(cartItems, d.excluded_items)
							const ci = d.entity === 'item'
								? cartItems.find((iv) => {
									const result = iv.id === d.variation_id && iv.batch_id === d.buy_batch_id

									if (result) {
										iv.quantity -= (iv.discounts
											? iv.discounts.reduce((sum, d) => {
												sum += d.quantity

												return sum
											}, 0)
											: 0)
									}

									return result
								})
								: cartCategories[d.category_id]

							if (!ci || (d.buy_condition_type === 'quantity' && d.buy_condition_value > ci.quantity) || (d.buy_condition_type === 'price' && d.buy_condition_value > ci.price * ci.quantity)) {
								addGetItemsFlag = false

								return false
							} else {
								buyItemsData.push(Math.floor(ci.quantity / d.buy_condition_value))
							}
						})

						for await (const d of getItems) {
							const discountQuantity = Math.min(Math.min(...buyItemsData) * d.get_discount_quantity, d.get_discount_max_quantity)
							let variation = await this.$bridge.getItemVariations(vuexCtx.state.deviceId, this.app.objToJson({
								id: d.variation_id
							}))

							variation = (typeof variation === 'string' ? JSON.parse(variation) : variation)
							variation = variation.data ? variation.data[0] : variation[0]

							if (variation) {
								const batch = variation.batch.find(b => (b.batch_id || b.id) === d.get_batch_id) || null

								if (addGetItemsFlag) {
									const cartItemIndex = cartItems.findIndex((iv) => {
										return iv.id === variation.id && iv.batch_id === d.get_batch_id && iv.price === (
											batch ? batch.price : variation.price
										)
									})

									if (cartItemIndex !== -1) {
										const discountIndex = cartItems[cartItemIndex].item_discount
											.findIndex(discount => discount.id === d.id)

										if (discountIndex !== -1) {
											let getDiscountQuantity = 0

											if (d.entity === 'category') {
												getDiscountQuantity = (d.buy_condition_type || cartItems[cartItemIndex].item_discount[discountIndex].quantity > 0)
													? (cartItems[cartItemIndex].quantity - cartItems[cartItemIndex].item_discount[discountIndex].quantity) + discountQuantity
													: discountQuantity
											} else {
												getDiscountQuantity = d.buy_condition_type
													? cartItems[cartItemIndex].quantity + discountQuantity
													: (cartItems[cartItemIndex].quantity - cartItems[cartItemIndex].item_discount[discountIndex].quantity) + discountQuantity
											}

											cartItems[cartItemIndex].quantity = getDiscountQuantity
											cartItems[cartItemIndex].item_discount[discountIndex].quantity = discountQuantity
										} else {
											cartItems[cartItemIndex].quantity += discountQuantity
											cartItems[cartItemIndex].item_discount.push({
												...d,
												type: 'COMBO',
												quantity: discountQuantity
											})
										}
									} else {
										cartItems.unshift({
											id: variation.id,
											item_id: variation.item_id,
											category_id: variation.item.category_id,
											brand_id: variation.item.brand_id,
											inventory_id: variation.inventory_id,
											batch_id: d.get_batch_id,
											kot_device_id: variation.kot_device_id,
											name: variation.name,
											item_name: variation.item.name,
											alternate_name: variation.custom_attributes.alternate_language,
											sku: variation.sku,
											type: variation.type,
											barcode: variation.barcode,
											hsn: variation.custom_attributes.hsn || '',
											unit_measure_type: variation.unit_measure_type,
											mrp: batch ? batch.mrp : (variation.custom_attributes.mrp || 0),
											price: batch ? batch.price : variation.price,
											quantity: d.get_discount_quantity,
											tax: variation.tax,
											discount: [],
											item_discount: [{
												...d,
												type: 'COMBO',
												quantity: d.get_discount_quantity
											}],
											combo_discount: [],
											itemization_type: variation.itemization_type || 'item',
											groups: [],
											notes: '',
											custom_attributes: variation.custom_attributes
										})
									}
								} else {
									const index = cartItems.findIndex((i) => {
										// eslint-disable-next-line
										return i.id === variation.id && i.batch_id === d.get_batch_id && i.price == (
											batch ? batch.price : variation.price
										)
									})

									if (index !== -1) {
										if (cartItems[index].quantity <= 0) {
											cartItems.splice(index, 1)
										} else {
											const discountIndex = cartItems[index].item_discount
											.findIndex(discount => discount.id === d.id)

											if (discountIndex !== -1) {
												cartItems[index].item_discount.splice(discountIndex, 1)
											}
										}
									}
								}
							}
						}
					}
				}
			}
		} else {
			const index = this.app.filterItem(cartItems, variation, 'index')

			if (index !== -1) {
				const itemComboDiscount = cartItems[index].combo_discount

				cartItems.splice(index, 1)

				if (itemComboDiscount?.length) {
					for await (const entity of discountTypes) {
						const buyItems = itemComboDiscount.filter(i => i.entity === entity && i.buy_condition_type && i.buy_condition_type.length !== 0)
						const getItems = itemComboDiscount.filter(i => i.entity === entity && i.get_discount_type && i.get_discount_type.length !== 0)
						const cartCategories = vuexCtx.getters.cartCategories(cartItems)
						const buyItemsData = []

						buyItems.forEach((d) => {
							const categoryQuantity = cartCategories[d.category_id]

							if (categoryQuantity) {
								buyItemsData.push(Math.floor(categoryQuantity.quantity / d.buy_condition_value))
							}
						})

						for await (const d of getItems) {
							const index = cartItems.findIndex(i => i.id === d.variation_id)

							if (index !== -1) {
								const discountIndex = cartItems[index].item_discount.findIndex(id => id.id === d.id)

								if (discountIndex !== -1) {
									if (d.entity === 'category' && d.get_discount_type !== 'cheapest' && buyItemsData.length) {
										const discountQuantity = Math.min(Math.min(...buyItemsData) * cartItems[index].item_discount[discountIndex].get_discount_quantity, cartItems[index].item_discount[discountIndex].get_discount_max_quantity)

										cartItems[index].quantity = discountQuantity
										cartItems[index].item_discount[discountIndex].quantity = discountQuantity
									} else {
										cartItems[index].quantity -= cartItems[index].item_discount[discountIndex].quantity
										cartItems[index].item_discount.splice(discountIndex, 1)
									}

									if (cartItems[index].quantity <= 0) {
										cartItems.splice(index, 1)
									}
								}
							}
						}
					}
				}
			}

			delete variation.cart_index
		}

		vuexCtx.commit('setCart', { items: cartItems })

		if (triggerCalculation) {
			await vuexCtx.dispatch('cartCalculation')
		}
	},
	async cartCalculation (vuexCtx, appliedAutoOrderDiscount = false) {
		let cartItems = JSON.parse(this.app.objToJson(vuexCtx.state.cart.items))
		const price = {
			subtotal: 0,
			tax: 0,
			taxes: {},
			discount: 0,
			discountedAmount: 0,
			discountedTax: 0,
			charge: 0,
			tip: 0,
			roundOff: 0,
			total: 0,
			additive_tax: 0,
			inclusive_tax: 0
		}

		const calculateTax = (item) => {
			let totalTax = 0
			const subtotal = this.app.$currency.transformNumber(item.subtotal - item.discountedAmount)

			item.taxes = []
			item.tax.forEach((tax, index) => {
				const taxData = {
					tax_id: tax.id,
					tax_name: tax.name,
					tax_rate: tax.rate,
					inclusion_type: tax.inclusion_type,
					discounted_tax: item.discountedTax
				}

				taxData.tax_amount = this.app.$currency.transformNumber(subtotal * (tax.rate / 100))
				totalTax += taxData.tax_amount

				if (index === item.tax.length - 1 && item.tax[0]?.inclusion_type === 'inclusive') {
					const deviation = this.app.$currency.transformNumber((item.totalInclusiveTax - totalTax))

					if ([-0.01, -0.001, 0.01, 0.001].includes(deviation)) {
						taxData.tax_amount += deviation
					}
				}

				item.taxes.push({ ...taxData })
				item.taxAmount = this.app.$currency.transformNumber(item.taxAmount + taxData.tax_amount)
				price.tax = this.app.$currency.transformNumber(price.tax + taxData.tax_amount)

				if (taxData.inclusion_type === 'additive') {
					price.additive_tax = this.app.$currency.transformNumber(price.additive_tax + taxData.tax_amount)
				} else {
					price.inclusive_tax = this.app.$currency.transformNumber(price.inclusive_tax + taxData.tax_amount)
				}

				if (price.taxes[taxData.tax_id]) {
					price.taxes[taxData.tax_id].tax_amount = this.app.$currency.transformNumber(price.taxes[taxData.tax_id].tax_amount + taxData.tax_amount)
					price.taxes[taxData.tax_id].discounted_tax = this.app.$currency.transformNumber(price.taxes[taxData.tax_id].discounted_tax + taxData.discounted_tax)
				} else {
					price.taxes[taxData.tax_id] = taxData
				}
			})
		}

		cartItems = cartItems.map((item) => {
			const total = this.app.$currency.transformNumber(item.price * item.quantity)

			item.subtotal = total
			item.taxAmount = 0
			item.discountedAmount = 0
			item.discountedTax = 0
			item.discounts = []

			if (item.tax[0]?.inclusion_type === 'inclusive') {
				const totalTaxRate = item.tax.reduce((sum, t) => {
					sum += t.rate

					return sum
				}, 0)

				item.totalInclusiveTax = this.app.$currency.transformNumber((total * (totalTaxRate / (100 + totalTaxRate))))
				item.subtotal = this.app.$currency.transformNumber(total - item.totalInclusiveTax)
				item.taxType = 'inclusive'
			} else {
				item.taxType = 'exclusive'
			}

			price.subtotal = this.app.$currency.transformNumber(price.subtotal + item.subtotal)

			if (
				!vuexCtx.state.settings.general.tax_calculation_phase ||
				vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount'
			) {
				calculateTax(item)
			}

			return item
		})

		// apply discount
		let totalItemDiscount = 0
		let leftOverDiscountRate = 0

		cartItems = cartItems.map((item) => {
			const cheapestItemCategoryDiscount = vuexCtx.getters.discounts('category').filter((d) => {
				d.entity = 'category'

				return d.get_discount_type === 'cheapest' && d.discount_categories.findIndex((i) => {
					return i.category_id === item.category_id && i.buy_condition_value
				}) !== -1
			})

			cheapestItemCategoryDiscount.forEach((discount) => {
				discount.discount_categories.forEach((dc) => {
					const totalCategoryQuantity = cartItems.reduce((sum, item) => {
						const discountIndex = item.item_discount.findIndex(d => d.id === discount.id)

						if (discountIndex > -1) {
							item.item_discount.splice(discountIndex, 1)
						}

						if (item.category_id === dc.category_id && !dc.excluded_items.includes(item.id)) {
							sum += item.quantity
						}

						return sum
					}, 0)
					const cheapestItem = cartItems.reduce((prev, curr) =>
						curr.category_id === dc.category_id && curr.price < prev.price && !dc.excluded_items.includes(curr.id)
							? curr
							: prev
					)

					if (
						dc.buy_condition_type === 'quantity' &&
						totalCategoryQuantity >= dc.buy_condition_value &&
						cheapestItem
					) {
						cheapestItem.item_discount.push({
							...dc,
							id: discount.id,
							name: discount.name,
							entity: discount.entity,
							type: 'CATEGORY',
							cheapest: true
						})
					}
				})
			})

			// item/category discount qty calculation
			item.discount = item.item_discount.reduce((discounts, discount) => {
				if (discount.entity !== 'dynamic' && discount.type === 'ITEM') {
					if ((
						discount.buy_condition_type === 'quantity' && item.quantity >= discount.buy_condition_value
					) || (discount.buy_condition_type === 'price' && (
						item.subtotal + (vuexCtx.state.settings.general.tax_calculation_phase === 'after_discount' ? 0 : item.taxAmount)
					) >= discount.buy_condition_value)) {
						const quantity = discount.get_discount_quantity === 0
							? Math.floor(
								item.quantity / discount.buy_condition_value
							) * discount.buy_condition_value
							: item.quantity

						if (quantity % discount.buy_condition_value === 0) {
							discount.quantity = Math.floor(item.quantity <= discount.get_discount_max_quantity
								? quantity
								: discount.get_discount_max_quantity)
						}

						discounts.push(discount)
					}
				} else if (discount.entity !== 'dynamic' && discount.type === 'CATEGORY') {
					if (discount.cheapest) {
						const totalCategoryQuantity = cartItems.reduce((sum, i) => {
							if (i.category_id === item.category_id && !discount.excluded_items.includes(i.id)) {
								sum += i.quantity
							}

							return sum
						}, 0)
						let quantity = Math.floor(
							totalCategoryQuantity / discount.buy_condition_value
						) * discount.buy_condition_value

						if (quantity % discount.buy_condition_value === 0) {
							quantity = Math.floor(quantity / discount.buy_condition_value)
							discount.quantity = quantity <= discount.get_discount_max_quantity
								? quantity
								: discount.get_discount_max_quantity
							discount.quantity = Math.min(item.quantity, discount.quantity)
						}
					} else {
						discount.quantity = item.quantity
					}

					discounts.push(discount)
				} else {
					discounts.push(discount)
				}

				return discounts
			}, [])

			// order discount
			if (vuexCtx.state.cart.selectedDiscount?.sku) {
				if (item.id === vuexCtx.state.cart.selectedDiscount.variation_id && item.discount.length === 0) {
					item.discount.push({
						...vuexCtx.state.cart.selectedDiscount,
						quantity: item.quantity
					})
				}
			} else if (vuexCtx.state.cart.selectedDiscount) {
				item.discount.push({
					...vuexCtx.state.cart.selectedDiscount,
					quantity: item.quantity
				})
			} else {
				const index = item.discount.findIndex(d => d.apply_discount_sub_total)

				if (index !== -1) {
					item.discount.splice(index, 1)
				}
			}

			// item discount
			item.discount.forEach((discount) => {
				let discountedAmount = 0
				let discountedTax = 0
				let taxes = []
				let discountAmountRate = 0

				if (item.quantity >= discount.quantity && (!discount.buy_condition_value || (discount.buy_condition_type === 'price' && price.subtotal >= discount.buy_condition_value) || discount.buy_condition_type === 'quantity')) {
					if (discount.get_discount_type === 'percentage') {
						discountedAmount += item.subtotal > 0
							? (((item.subtotal - item.discountedAmount) / item.quantity) * discount.quantity || item.quantity) * (
								discount.get_discount_value / 100
							)
							: 0

						if (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount') {
							discountedTax += item.taxAmount > 0
								? (((item.taxAmount - item.discountedTax) / item.quantity) * discount.quantity || item.quantity) * (
									discount.get_discount_value / 100
								)
								: 0
						}
					} else if (discount.get_discount_type === 'price') {
						const total = price.subtotal + (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount' ? price.tax : 0)
						const itemTotal = item.subtotal + (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount' ? item.taxAmount : 0)

						if (discount.get_discount_value < total) {
							discountAmountRate = ['dynamic', 'item', 'category'].includes(discount.entity) ? 1 : parseFloat(itemTotal / total) + leftOverDiscountRate
							const totalDiscountedAmount = discount.get_discount_value * discountAmountRate

							discountedAmount += item.subtotal / itemTotal * totalDiscountedAmount

							if (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount') {
								discountedTax += item.taxAmount / itemTotal * totalDiscountedAmount
							}

							if ((discount.entity !== 'dynamic' || discount.apply_discount_on_each_item) && !discount.apply_discount_sub_total) {
								discountedAmount += discountedAmount * (discount.quantity - 1)

								if (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount') {
									discountedTax += discountedTax * (discount.quantity - 1)
								}
							}
						} else {
							discountedAmount += item.subtotal - item.discountedAmount

							if (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount') {
								discountedTax += item.taxAmount - item.discountedTax
							}
						}
					} else {
						return
					}
				}

				if ((price.discount - totalItemDiscount) <= discount.max_discount_value) {
					const totalDiscount = discountedAmount + discountedTax

					if (discount.max_discount_value > 0 && ((
						(price.discount - totalItemDiscount) + totalDiscount
					) > discount.max_discount_value)) {
						const availableDiscountValue = discount.max_discount_value - (price.discount - totalItemDiscount)

						discountedAmount = availableDiscountValue * (discountedAmount / totalDiscount)
						discountedTax = availableDiscountValue * (discountedTax / totalDiscount)
					}
				}

				if (vuexCtx.state.settings.general.tax_calculation_phase === 'before_discount') {
					taxes = item.taxes.map((tax) => {
						tax.discounted_tax = item.taxAmount > 0
							? this.app.$currency.transformNumber(tax.discounted_tax + ((tax.tax_amount / item.taxAmount) * discountedTax))
							: 0
						price.taxes[tax.tax_id].discounted_tax += tax.discounted_tax

						return tax
					})
				}

				if (item.discountedAmount <= item.subtotal + (vuexCtx.state.settings.general.tax_calculation_phase === 'after_discount' ? 0 : item.taxAmount)) {
					item.discounts.push({
						...discount,
						discounted_amount: this.app.$currency.transformNumber(discountedAmount),
						discounted_tax: this.app.$currency.transformNumber(discountedTax)
					})
					item.discountedAmount = this.app.$currency.transformNumber(item.discountedAmount + discountedAmount)
					item.discountedTax = this.app.$currency.transformNumber(item.discountedTax + discountedTax)

					if (taxes.length) {
						item.taxes = taxes
					}

					price.discountedAmount = this.app.$currency.transformNumber(price.discountedAmount + discountedAmount)
					price.discountedTax = this.app.$currency.transformNumber(price.discountedTax + discountedTax)
					price.discount = this.app.$currency.transformNumber(price.discount + discountedAmount + discountedTax)

					if (['dynamic', 'item', 'category'].includes(discount.entity)) {
						totalItemDiscount += this.app.$currency.transformNumber(discountedAmount + discountedTax)
					}

					if (leftOverDiscountRate) {
						leftOverDiscountRate = 0
					}
				} else if (discount.get_discount_type === 'price') {
					leftOverDiscountRate += discountAmountRate
				}
			})

			if (vuexCtx.state.settings.general.tax_calculation_phase === 'after_discount') {
				if (item.discountedAmount) {
					const totalTaxRate = item.tax.reduce((sum, t) => {
						sum += t.rate

						return sum
					}, 0)

					item.totalInclusiveTax = this.app.$currency.transformNumber(((item.subtotal - item.discountedAmount) * (totalTaxRate / 100)))
				}

				calculateTax(item)
			}

			return item
		})

		price.total = this.app.$currency.transformNumber(price.subtotal + price.tax - price.discount)
		price.charges = vuexCtx.state.cart.charges.concat(vuexCtx.state.charges.filter((c) => {
			return vuexCtx.state.cart.charges.findIndex(cc => cc.id === c.id) === -1 && c.is_automatic && !vuexCtx.state.cart.ignoredCharges.includes(c.id) && (
				!vuexCtx.state.cart.priceCategory || !c.excluded_price_categories?.includes(vuexCtx.state.cart.priceCategory.id)
			) && this.app.validateConditions(price, c.conditions)
		})).reduce((acc, charge) => {
			let chargeAmount = 0
			let chargeTax = 0
			let inclusivePercent = 0
			let exclusivePercent = 0

			charge.taxes = []

			if (charge.category !== 'delivery_charge' || vuexCtx.state.cart.storeOrder?.orderType === 'delivery') {
				if (charge.type === 'fixed') {
					chargeAmount = this.app.$currency.transformNumber(charge.value)
				} else if (charge.type === 'percentage' && price[charge.applicable_on]) {
					chargeAmount = this.app.$currency.transformNumber(price[charge.applicable_on] * (Math.abs(charge.value) / 100))
				}
			}

			if (vuexCtx.state.cart.storeOrder?.orderType === 'delivery' &&
				charge.category === 'delivery_charge' &&
				charge.conditions &&
				Object.keys(charge.conditions).length &&
				vuexCtx.state.cart.storeOrder.deliveryAddress?.distance?.value > charge.conditions.distance[0]?.value
			) {
				const conditions = charge.conditions.distance[0]

				chargeAmount += this.app.$currency.transformNumber(parseFloat(((
					(Math[+conditions.round_up ? 'ceil' : 'floor'](vuexCtx.state.cart.storeOrder.deliveryAddress.distance.value / 1000)) -
					((charge.value || +conditions.is_free_shipping) ? (conditions.value / 1000) : 0)
				) * (conditions.multiplier_value / conditions.multiplier)).toFixed(2)))
			}

			if (charge.tax) {
				charge.tax.forEach((tax) => {
					const taxData = {
						tax_id: tax.id,
						tax_name: tax.name,
						tax_rate: tax.rate,
						tax_amount: 0,
						inclusion_type: tax.inclusion_type,
						discounted_tax: 0
					}

					if (tax.inclusion_type === 'inclusive') {
						charge.taxes.push(taxData)
						inclusivePercent += tax.rate
					} else {
						taxData.tax_amount = this.app.$currency.transformNumber(chargeAmount * (tax.rate / 100))
						charge.taxes.push(taxData)
						exclusivePercent += tax.rate
					}
				})

				if (exclusivePercent > 0) {
					chargeTax = this.app.$currency.transformNumber(chargeAmount * (exclusivePercent / 100))
					price.additive_tax = this.app.$currency.transformNumber(price.additive_tax + chargeTax)
				} else if (inclusivePercent > 0) {
					const totalCharge = this.app.$currency.transformNumber(chargeAmount)
					let totalTax = 0

					chargeAmount = this.app.$currency.transformNumber(totalCharge - chargeAmount * (inclusivePercent / (100 + inclusivePercent)))
					charge.taxes = charge.taxes.map((t, index) => {
						let taxAmount = this.app.$currency.transformNumber(chargeAmount * (t.tax_rate / 100))

						totalTax += taxAmount

						if (index === charge.taxes.length - 1) {
							const deviation = this.app.$currency.transformNumber((totalCharge - chargeAmount) - totalTax)

							taxAmount += deviation
						}

						chargeTax += taxAmount
						price.inclusive_tax = this.app.$currency.transformNumber(price.inclusive_tax + taxAmount)

						return Object.assign({}, t, { tax_amount: taxAmount })
					})
				} else {
					charge.taxes = []
				}

				if (charge.taxes) {
					charge.taxes.forEach((tax) => {
						tax = Object.assign({}, tax)

						if (price.taxes[tax.tax_id]) {
							price.taxes[tax.tax_id].tax_amount = this.app.$currency.transformNumber(price.taxes[tax.tax_id].tax_amount + tax.tax_amount)
						} else {
							price.taxes[tax.tax_id] = tax
						}
					})
				}
			}

			price.charge = this.app.$currency.transformNumber(price.charge + chargeAmount)
			price.tax = this.app.$currency.transformNumber(price.tax + chargeTax)
			price.total = this.app.$currency.transformNumber(price.total + chargeAmount + chargeTax)

			if (chargeAmount > 0) {
				acc.push({
					id: charge.id,
					name: charge.name,
					slug: charge.category,
					amount: chargeAmount,
					value: charge.value,
					tax: chargeTax,
					taxes: charge.taxes,
					type: charge.type,
					applicable_on: charge.applicable_on
				})
			}

			return acc
		}, [])

		if (vuexCtx.state.tip) {
			price.tip = vuexCtx.state.tip.type === 'fixed'
				? this.app.$currency.transformNumber(vuexCtx.state.tip.value)
				: this.app.$currency.transformNumber(price.total * (vuexCtx.state.tip.value / 100))
			price.total = this.app.$currency.transformNumber(price.total + price.tip)
		}

		price.taxes = Object.values(price.taxes)

		if (+vuexCtx.state.settings.general.round_off_total) {
			price.total = this.app.$currency.transformNumber(price.total)

			const roundedTotal = Math.round(price.total)

			price.roundOff = roundedTotal - price.total
			price.total = roundedTotal
		}

		vuexCtx.commit('setCart', { items: cartItems, price })

		if (!appliedAutoOrderDiscount && (!vuexCtx.state.cart.selectedDiscount || !['loyalty', 'gift card'].includes(vuexCtx.state.cart.selectedDiscount.name?.toLowerCase()))) {
			await vuexCtx.dispatch('orderAutoDiscount')
		}
	},
	async orderAutoDiscount (vuexCtx) {
		let total = this.app.$currency.transformNumber(vuexCtx.state.cart.price.total)
		const cartItems = [...vuexCtx.state.cart.items]

		if (vuexCtx.state.cart.selectedDiscount?.is_automatic) {
			total += cartItems.reduce((discountSum, i) => {
				const index = i.discounts.findIndex(d => d.apply_discount_sub_total && d.is_automatic)

				if (index !== -1) {
					discountSum += i.discounts[index].discounted_amount + i.discounts[index].discounted_tax
				}

				return discountSum
			}, 0)
			vuexCtx.commit('setCart', { selectedDiscount: null })
		}

		const autoOrderDiscount = vuexCtx.getters.autoOrderDiscount('price', total)
		const orderDiscountItemIndexes = cartItems.reduce((indexes, item, index) => {
			const discountIndex = item.discounts.findIndex(d => d.type === 'ORDER')

			if (discountIndex !== -1) {
				indexes[index] = item.discounts[discountIndex].quantity
			}

			return indexes
		}, {})

		if (autoOrderDiscount) {
			if (autoOrderDiscount.discount_items && autoOrderDiscount.discount_items.length) {
				const indexes = Object.keys(orderDiscountItemIndexes)

				for (let i = indexes.length - 1; i >= 0; i--) {
					const quantity = cartItems[indexes[i]].quantity - orderDiscountItemIndexes[indexes[i]]

					if (quantity <= 0) {
						cartItems.splice(indexes[i], 1)
					} else {
						cartItems[indexes[i]].item_discount = []
					}
				}

				for await (const d of autoOrderDiscount.discount_items) {
					let variation = await this.$bridge.getItemVariations(vuexCtx.state.deviceId, this.app.objToJson({
						id: d.variation_id
					}))

					variation = typeof variation === 'string' ? JSON.parse(variation) : variation
					variation = variation.data ? variation.data[0] : variation[0]

					if (variation) {
						const batch = variation.batch.find(b => (b.batch_id || b.id) === d.get_batch_id) || null
						const cartItemIndex = cartItems.findIndex((iv) => {
							return iv.id === variation.id && iv.batch_id === d.get_batch_id && iv.price === (
								batch ? batch.price : variation.price
							)
						})

						if (cartItemIndex !== -1) {
							cartItems[cartItemIndex].item_discount.push({
								...d,
								id: autoOrderDiscount.id,
								name: autoOrderDiscount.name,
								type: 'ORDER',
								quantity: d.get_discount_quantity
							})
						} else {
							cartItems.unshift({
								id: variation.id,
								item_id: variation.item_id,
								category_id: variation.item.category_id,
								brand_id: variation.item.brand_id,
								inventory_id: variation.inventory_id,
								batch_id: d.get_batch_id,
								kot_device_id: variation.kot_device_id,
								name: variation.name,
								item_name: variation.item.name,
								alternate_name: variation.custom_attributes.alternate_language,
								sku: variation.sku,
								type: variation.type,
								barcode: variation.barcode,
								hsn: variation.custom_attributes.hsn || '',
								unit_measure_type: variation.unit_measure_type,
								mrp: batch ? batch.mrp : (variation.custom_attributes.mrp || 0),
								price: batch ? batch.price : variation.price,
								quantity: d.get_discount_quantity,
								tax: variation.tax,
								discount: [],
								item_discount: [{
									...d,
									id: autoOrderDiscount.id,
									name: autoOrderDiscount.name,
									type: 'ORDER',
									quantity: d.get_discount_quantity
								}],
								combo_discount: [],
								itemization_type: variation.itemization_type || 'item',
								groups: [],
								notes: '',
								custom_attributes: variation.custom_attributes
							})
						}
					}
				}

				vuexCtx.commit('setCart', { items: cartItems })
				vuexCtx.dispatch('cartCalculation', true)
			} else {
				if (Object.keys(orderDiscountItemIndexes).length) {
					const indexes = Object.keys(orderDiscountItemIndexes)

					for (let i = indexes.length - 1; i >= 0; i--) {
						cartItems.splice(indexes[i], 1)
					}

					vuexCtx.commit('setCart', { items: cartItems })
				}

				vuexCtx.commit('setCart', { selectedDiscount: autoOrderDiscount })
				vuexCtx.dispatch('cartCalculation', true)
			}
		} else if (Object.keys(orderDiscountItemIndexes).length > 0) {
			const indexes = Object.keys(orderDiscountItemIndexes)

			for (let i = indexes.length - 1; i >= 0; i--) {
				const quantity = cartItems[indexes[i]].quantity - orderDiscountItemIndexes[indexes[i]]

				if (quantity <= 0) {
					cartItems.splice(indexes[i], 1)
				} else {
					cartItems[indexes[i]].item_discount = []
				}
			}

			vuexCtx.commit('setCart', { items: cartItems })
			await vuexCtx.dispatch('cartCalculation')
		} else if (vuexCtx.state.cart.selectedDiscount?.is_automatic) {
			await vuexCtx.dispatch('cartCalculation')
		}
	},
	uploadToS3 (vuexCtx, body) {
		return this.$axios({
			method: 'post',
			url: '/api/s3/upload',
			data: body
		}).catch(console.error)
	},
	checkSubscription (vuexCtx) {
		if (vuexCtx.state.merchant.subscription) {
			const today = this.$moment(new Date())
			const endDate = this.$moment.utc(vuexCtx.state.merchant.subscription.end_date).local()
			let expiresInDays = 0
			let expiresInSeconds = 0

			if (['active', 'in_trial'].includes(vuexCtx.state.merchant.subscription.status?.short_name)) {
				expiresInDays = endDate.diff(today, 'days')
				expiresInSeconds = endDate.diff(today, 'seconds')
			}

			vuexCtx.commit('setState', {
				key: 'merchant',
				value: {
					...vuexCtx.state.merchant,
					subscription: {
						...vuexCtx.state.merchant.subscription,
						show_modal: vuexCtx.state.merchant.subscription.period === 'month' // display subscription alert
							? expiresInDays <= 3 // 3 days before for monthly
							: expiresInDays <= 15, // 15 days before for annual
						expires_in_days: expiresInDays,
						expires_in_seconds: expiresInSeconds
					}
				},
				save: true
			})

			if (expiresInSeconds > 0 && expiresInSeconds <= 86400) {
				setTimeout(() => {
					vuexCtx.dispatch('checkSubscription')
				}, expiresInSeconds * 1000)
			}
		}
	},
	getResource (vuexCtx, { resource, params }) {
		return this.$axios.$get(`/api/pos/v2/resource/${resource}`, {
			params
		})
	},
	getLoyaltyPoints (vuexCtx, data) {
		return this.$axios.$get(`/api/pos${data.loyalty_type === 'advanced_loyalty' ? '/v2' : ''}/loyalty`, {
			params: data
		})
	},
	getS3Url (vuexCtx, data) {
		return this.$axios.$get('/api/pos/v2/s3-url', {
			params: data
		})
	},
	holdCart (vuexCtx, switchEmployee = false) {
		if (vuexCtx.state.cart.items.length) {
			const empId = this.app.objToJson(vuexCtx.state.employee.id)
			const onHold = { ...vuexCtx.state.onHold }

			vuexCtx.state.cart.createdAt = new Date()
			vuexCtx.state.cart.switchEmployee = switchEmployee

			if (onHold[empId]) {
				onHold[empId].unshift(vuexCtx.state.cart)
			} else {
				onHold[empId] = [{ ...vuexCtx.state.cart }]
			}

			vuexCtx.commit('setState', {
				key: 'onHold',
				value: onHold,
				save: true
			})
			vuexCtx.commit('resetCart')
		}
	},
	addHoldToCart (vuexCtx, { index = 0, switchEmployee = false }) {
		const empId = this.app.objToJson(vuexCtx.state.employee.id)

		if (vuexCtx.state.onHold[empId] &&
			vuexCtx.state.onHold[empId].length &&
			(!switchEmployee ||
				vuexCtx.state.onHold[empId].filter(oh => oh.switchEmployee === true).length)) {
			const onHold = { ...vuexCtx.state.onHold }
			const cart = onHold[empId].splice(index, 1)[0]

			if (cart) {
				delete cart.createdAt
				delete cart.switchEmployee
				vuexCtx.commit('setState', { key: 'selectedOrder', value: null })
				vuexCtx.commit('setCart', cart)
				vuexCtx.commit('setState', { key: 'onHold', value: onHold, save: true })
			}
		}
	},
	removeOnHold (vuexCtx, index) {
		const empId = this.app.objToJson(vuexCtx.state.employee.id)

		if (vuexCtx.state.onHold[empId]) {
			const onHold = { ...vuexCtx.state.onHold }

			onHold[empId].splice(index, 1)
			vuexCtx.commit('setState', {
				key: 'onHold',
				value: onHold,
				save: true
			})
		}
	},
	initTidyPay (vuexCtx, params) {
		vuexCtx.state.tidyPay.cancelTidyPayRequest = this.$axios.CancelToken.source()
		vuexCtx.state.tidyPay.showModal = true

		let data = {
			requestType: 'sale',
			userName: vuexCtx.state.tidyPay.userName,
			password: vuexCtx.state.tidyPay.password,
			accountId: vuexCtx.state.tidyPay.accountId,
			terminalId: vuexCtx.state.tidyPay.terminalId,
			amount: params.amount,
			receiptMode: 'N',
			transactionCode: params.refCode
		}

		if (params.customer_email) {
			data = {
				...data,
				receiptMode: 'E',
				email: params.customer_email
			}
		}

		return new Promise((resolve, reject) => {
			this.$axios.post('/api/tidypay', data, {
				cancelToken: vuexCtx.state.tidyPay.cancelTidyPayRequest.token
			}).then((response) => {
				if (response.data.responseCode === 'A01') {
					vuexCtx.state.tidyPay.status = response.data.responseMessage.toUpperCase()
					vuexCtx.state.tidyPay.code = response.data.responseCode
					resolve(response.data)
				} else if (response.data.failureCode === 'L01') {
					vuexCtx.state.tidyPay.status = 'FAILED'
					vuexCtx.state.tidyPay.code = response.data.failureCode
				} else {
					vuexCtx.state.tidyPay.status = 'FAILED'
					vuexCtx.state.tidyPay.code = response.data.responseCode
					vuexCtx.dispatch('tidyPayErrorCb')
				}

				setTimeout(() => {
					vuexCtx.dispatch('resetTidyPay')
					reject(new Error('reset tidypay'))
				}, 3000)
			}).catch(() => {
				vuexCtx.dispatch('tidyPayErrorCb')
				vuexCtx.dispatch('resetTidyPay')
				reject(new Error('reset tidypay'))
			})
		})
	},
	cancelTidyPayTransaction (vuexCtx) {
		vuexCtx.state.tidyPay.cancelTidyPayRequest.cancel()
		setTimeout(() => { vuexCtx.dispatch('resetTidyPay') }, 3000)
	},
	tidyPayErrorCb (vuexCtx) {
		vuexCtx.state.tidyPay.status = 'FAILED'
		setTimeout(() => { vuexCtx.dispatch('resetTidyPay') }, 3000)
	},
	resetTidyPay (vuexCtx) {
		vuexCtx.state.tidyPay = {
			...vuexCtx.state.tidyPay,
			showModal: false,
			status: 'INITIALIZING',
			code: null
		}
	},
	async initClover (vuexCtx, amount) {
		vuexCtx.state.clover.showModal = true

		try {
			let response = null
			const date = new Date()

			vuexCtx.state.clover.status = 'PENDING'

			if (Math.floor(Date.now() / 1000) >= vuexCtx.state.clover.secret.access_token_expiration) {
				response = await this.$axios.get('/api/clover/refresh', {
					headers: { 'x-merchant-code': vuexCtx.state.merchant.mCode }
				})
				vuexCtx.state.clover.secret = response.data.data
			}

			if (!vuexCtx.state.cart.price.tip && vuexCtx.state.settings.general.tip_mandatory) {
				response = await this.$axios.post('/api/clover/tip', {
					baseAmount: parseFloat((amount * 100).toFixed(2)),
					tipSuggestions:
						vuexCtx.state.settings.general.tip_percentages?.map(
							(tip) => {
								return {
									name: `${this.app.$currency.toCurrency((tip * amount) / 100)}`,
									percentage: parseFloat(+(tip).toFixed(2))
								}
							}
						)
				}, {
					headers: {
						Authorization: `Bearer ${vuexCtx.state.clover.secret.access_token}`,
						'X-Clover-Device-Id': vuexCtx.state.clover.serialNumber,
						'X-POS-ID': 'PosBytz Complete'
					},
					timeout: 150 * 1000
				})

				if (response.data.response) {
					vuexCtx.state.tip = { type: 'fixed', value: response.data.response / 100 }
					await vuexCtx.dispatch('cartCalculation')
				}
			}

			response = await this.$axios.post('/api/clover/payments', {
				amount: parseFloat((amount * 100).toFixed(2)),
				final: true,
				externalPaymentId: date.getTime()
			}, {
				headers: {
					Authorization: `Bearer ${vuexCtx.state.clover.secret.access_token}`,
					'X-Clover-Device-Id': vuexCtx.state.clover.serialNumber,
					'X-POS-ID': 'PosBytz Complete',
					'Idempotency-Key': date.getTime()
				},
				timeout: 150 * 1000
			})

			vuexCtx.state.clover.status = response.data?.payment?.result === 'SUCCESS'
				? 'SUCCESS'
				: 'FAILED'
			await this.app.sleep(3000)

			return response
		} catch (err) {
			console.error(err)
		} finally {
			vuexCtx.dispatch('resetClover')
			this.$axios.post('/api/clover/welcome', null, {
				headers: {
					Authorization: `Bearer ${vuexCtx.state.clover.secret.access_token}`,
					'X-Clover-Device-Id': vuexCtx.state.clover.serialNumber,
					'X-POS-ID': 'PosBytz Complete'
				}
			})
		}
	},
	cancelClover (vuexCtx) {
		vuexCtx.state.clover.cancel = true
		this.$axios.post('/api/clover/cancel', null, {
			headers: {
				Authorization: `Bearer ${vuexCtx.state.clover.secret.access_token}`,
				'X-Clover-Device-Id': vuexCtx.state.clover.serialNumber,
				'X-POS-ID': 'PosBytz Complete'
			}
		})
	},
	resetClover (vuexCtx) {
		vuexCtx.state.clover.showModal = false
		setTimeout(() => {
			vuexCtx.state.clover.status = 'INITIALIZING'
			vuexCtx.state.clover.cancel = false
		}, 500)
	},
	createOrder (vuexCtx, data) {
		if (vuexCtx.state.merchant.subscription.slug !== 'mini' && !vuexCtx.getters.isTestingDevice) {
			if (data.orders[0].scheduled_at) {
				return this.$axios.post('/api/online-orders', data.orders[0])
			} else if ((
				!data.orders[0].custom_attributes?.is_custom_refund &&
				(
					vuexCtx.state.settings.general.enable_inventory_stock_restriction ||
					vuexCtx.state.location.platforms?.includes('zatca') ||
					vuexCtx.getters.isGiftCard
				)
			) && ['open', 'closed', 'completed'].includes(data.orders[0].status)) {
				const orderObj = JSON.parse(this.app.objToJson(data))

				orderObj.orders[0].tables = orderObj.orders[0].tables.map(table => table.id)

				return this.$axios.post('/api/pos/create-order', orderObj)
			} else if (
				data.orders[0].custom_attributes.platform === 'store' &&
				data.orders[0].order_type === 'delivery'
			) {
				const currentOrder = data.orders[0]
				const customer = currentOrder.customers[0]

				return new Promise((resolve, reject) => {
					this.$axios.$post('/api/store-orders/verify-phone', {
						phone: {
							number: customer.phone,
							code: vuexCtx.state.merchant.countryCode
						},
						client: 'pos'
					}, {
						headers: {
							'x-api-merchant': vuexCtx.state.merchant.mCode
						}
					}).catch(console.error).finally(() => {
						return this.$axios.$post('/api/store-orders/basic-register', {
							name: customer.first_name + ' ' + customer.last_name,
							phone: {
								number: customer.phone,
								code: vuexCtx.state.merchant.countryCode
							}
						}, {
							headers: {
								'x-api-merchant': vuexCtx.state.merchant.mCode
							}
						}).then((registerResponse) => {
							const customerId = registerResponse.data.info.id
							const customAttributes = {}

							currentOrder.customers[0].store_customer_id = customerId

							if (vuexCtx.state.settings.general.tax_calculation_phase) {
								customAttributes.tax_calculation_phase = vuexCtx.state.settings.general.tax_calculation_phase
							}

							const orderObject = {
								store_id: vuexCtx.state.locationId,
								payment_method: currentOrder.order_payments[0].slug,
								customer: {
									...currentOrder.customers[0],
									id: currentOrder.customers[0].store_customer_id,
									name: currentOrder.customers[0].first_name + ' ' + currentOrder.customers[0].last_name,
									phone: { number: currentOrder.customers[0].phone, code: currentOrder.customers[0].calling_code }
								},
								instructions: '',
								shipping_address: currentOrder.custom_attributes.delivery_address || {},
								billing_address: currentOrder.custom_attributes.delivery_address || {},
								sub_total: currentOrder.sub_total,
								tax: currentOrder.total_tax,
								inclusive_tax: currentOrder.inclusive_tax,
								additive_tax: currentOrder.additive_tax,
								discount: currentOrder.total_discount,
								discounted_amount: currentOrder.total_discounted_amount,
								discounted_tax: currentOrder.total_discounted_tax,
								order_discount: currentOrder.discounts.length
									? {
										discount_id: currentOrder.discounts[0].id,
										// code: currentOrder.discounts[0].code, // ? - (Unavailable, only dynamic discount not working without it)
										name: currentOrder.discounts[0].name,
										amount: currentOrder.total_discounted_amount,
										tax: currentOrder.total_discounted_tax
									}
									: {},
								charge: currentOrder.total_charge,
								charges: currentOrder.charges.map((charge) => {
									return {
										...charge,
										value: charge.amount,
										taxes: charge.taxes?.map((t) => {
											return {
												id: t.tax_id,
												name: t.tax_name,
												rate: t.tax_rate,
												is_active: true,
												tax_amount: t.tax_amount
											}
										})
									}
								}),
								round_off_amount: currentOrder.round_off_amount,
								total: currentOrder.total_price,
								due_amount: currentOrder.total_price,
								order_type: currentOrder.order_type,
								order_date: this.$moment.utc(currentOrder.created_at).format('YYYY-MM-DD HH:mm:ss'),
								scheduled_at: null,
								custom_attributes: currentOrder.custom_attributes,
								merchant_price_category_id: currentOrder.price_category?.id || null,
								items: currentOrder.items.reduce((items, item) => {
									if (item.quantity > 0) {
										const customAttributes = {
											notes: item.notes || null
										}

										items.push({
											item_id: item.item_variation_id,
											item_name: item.item_variation_name,
											qty: item.quantity,
											unit_cost: item.single_quantity_amount,
											mrp: item.mrp,
											alt_lang: item.alternate_name,
											food_type: item.food_type, // ? - (Unavailable, but working without it)
											sub_total: item.sub_total,
											tax: item.tax,
											taxes: item.tax_details.map((tax) => {
												return {
													id: tax.tax_id,
													name: tax.tax_name,
													rate: tax.tax_rate,
													amount: tax.tax_amount
												}
											}),
											discount: item.discount,
											discounted_amount: item.discounted_amount,
											discounted_tax: item.discounted_tax,
											item_discount: item.discount > 0
												? {
													discount_id: item.discount_details[0].id,
													code: item.discount_details[0].code, // ? - (Unavailable, but working without it)
													name: item.discount_details[0].name,
													amount: item.discount_details[0].discounted_amount,
													tax: item.discount_details[0].discounted_tax
												}
												: {},
											total: item.total,
											combos: item.groups?.length
												? item.groups.reduce((modifiers, g) => {
													if (g.type === 'combo') {
														modifiers.push({
															group_id: g.item_variation_group_id,
															item_id: g.group_item_variation_id,
															item_name: g.item_variation_name,
															price: g.price,
															qty: 1
														})
													}

													return modifiers
												}, [])
												: [],
											modifiers: item.groups?.length
												? item.groups.reduce((modifiers, g) => {
													if (g.type === 'modifier') {
														modifiers.push({
															group_id: g.item_variation_group_id,
															item_id: g.group_item_variation_id,
															item_name: g.item_variation_name,
															price: g.price,
															qty: 1
														})
													}

													return modifiers
												}, [])
												: [],
											custom_attributes: customAttributes
										})
									}

									return items
								}, [])
							}

							return orderObject
						}).then((orderObject) => {
							return this.$axios.post('/api/store-orders/place-order', orderObject, {
								headers: {
									'x-api-merchant': vuexCtx.state.merchant.mCode
								}
							})
						}).then(resolve).catch((err) => {
							reject(err.response?.data?.data ? err.response.data.data : err)
						})
					})
				})
			}
		}

		return Promise.resolve()
	},
	validateOrder (vuexCtx, order) {
		return !order.custom_attributes?.is_custom_refund &&
			vuexCtx.state.merchant.subscription.slug !== 'mini' &&
			vuexCtx.state.settings.general.enable_inventory_stock_restriction &&
			(vuexCtx.state.cart.storeOrder?.orderType !== 'delivery' || vuexCtx.state.isOnline)
				? this.$axios.post('/api/pos/order-validate', {
					device_id: vuexCtx.state.deviceId,
					location_id: vuexCtx.state.locationId,
					employee_id: vuexCtx.state.employee.id,
					orders: [{
						receipt_code: order.receipt_code,
						order_id: order.order_id,
						items: vuexCtx.state.cart.items.reduce((items, itemVariation, index) => {
							const orderItem = order.id ? this.app.filterItem(order.items, itemVariation) : null
							const item = {
								item_id: itemVariation.item_id,
								category_id: itemVariation.category_id,
								brand_id: itemVariation.brand_id,
								item_variation_id: itemVariation.id,
								item_inventory_id: itemVariation.inventory_id,
								batch_id: typeof itemVariation.batch_id === 'number' ? itemVariation.batch_id : null,
								merchant_price_category_id: itemVariation.price_category_id,
								kot_device_id: itemVariation.kot_device_id,
								item_code: orderItem
									? orderItem.item_code
									: `${vuexCtx.state.deviceId}${itemVariation.id}${new Date().valueOf()}${index}`,
								item_name: itemVariation.item_name,
								item_variation_name: itemVariation.name,
								alternate_name: itemVariation.alternate_name,
								barcode: itemVariation.barcode,
								hsn: itemVariation.hsn,
								itemization_type: itemVariation.itemization_type,
								unit_measure_type: itemVariation.unit_measure_type,
								mrp: itemVariation.mrp,
								single_quantity_amount: itemVariation.price,
								quantity: Number(itemVariation.quantity),
								groups: itemVariation.groups,
								notes: itemVariation.notes,
								sub_total: itemVariation.subtotal,
								total: (itemVariation.subtotal + itemVariation.taxAmount) - itemVariation.discountedAmount,
								gross_sales: itemVariation.subtotal,
								net_sales: itemVariation.subtotal - itemVariation.discountedAmount,
								tax: itemVariation.taxAmount,
								tax_type: itemVariation.taxType,
								tax_details: itemVariation.taxes,
								item_discount: itemVariation.item_discount,
								discount: itemVariation.discountedAmount,
								discounted_amount: itemVariation.discountedAmount,
								discounted_tax: itemVariation.discountedTax,
								discount_details: itemVariation.discounts
							}

							items.unshift(item)

							return items
						}, [])
					}]
				})
				: Promise.resolve()
	},
	async getPrimaryDevice (vuexCtx, show) {
		if (this.$bridge.getPrimaryDevice) {
			vuexCtx.commit('setLoading', { show: true, text: 'connecting to primary device' })
			await this.app.sleep(10)

			let primaryDevice = await this.$bridge.getPrimaryDevice()

			if (typeof primaryDevice === 'string') {
				primaryDevice = JSON.parse(primaryDevice)
			}

			if (primaryDevice && Object.keys(primaryDevice).length === 0) {
				primaryDevice = null
			}

			if (show) {
				vuexCtx.commit('setLoading', {
					show,
					text: primaryDevice ? 'connected to primary device' : 'cannot connect to primary device',
					status: primaryDevice ? 'success' : 'fail'
				})
			}

			vuexCtx.commit('setState', {
				key: 'primaryDevice',
				value: primaryDevice ? { ...primaryDevice } : null,
				save: true
			})

			if (show) {
				await this.app.sleep(1500)
			}

			vuexCtx.commit('resetLoading')
			await this.app.sleep(300)
		} else {
			return null
		}
	},
	async checkPrimaryDevice (vuexContext) {
		const primaryDevice = await this.$bridge.getLocalStorage('primaryDevice')

		if (vuexContext.state.primaryDevice?.ip !== primaryDevice?.ip) {
			vuexContext.commit('setState', {
				key: 'primaryDevice',
				value: null
			})
		}
	},
	async generateReceiptCode (vuexCtx) {
		if (typeof this.$bridge.generateReceiptCode === 'function') {
			if (vuexCtx.state.settings.general.enable_token_number_system && !vuexCtx.state.device.is_primary && !vuexCtx.state.primaryDevice) {
				vuexCtx.commit('setLoading', {
					show: true,
					text: 'connecting to primary device'
				})
			}

			let response = await vuexCtx.dispatch('bridgeCall', {
				methodName: 'generateReceiptCode',
				args: [
					!vuexCtx.state.settings.general.enable_token_number_system || vuexCtx.state.device.is_primary
						? null
						: vuexCtx.state.primaryDevice?.ip
				]
			})

			if (typeof response !== 'string' && response?.code) {
				response = response.code
			}

			requestAnimationFrame(() => vuexCtx.commit('resetLoading'))

			return response
		} else {
			const moment = this.$moment()
			let orderCount = null

			if (typeof this.$bridge.getLastOrder === 'function') {
				if (vuexCtx.state.settings.general.enable_token_number_system && !vuexCtx.state.device.is_primary && !vuexCtx.state.primaryDevice) {
					vuexCtx.commit('setLoading', {
						show: true,
						text: 'connecting to primary device'
					})
				}

				let response = await vuexCtx.dispatch('bridgeCall', {
					methodName: 'getLastOrder',
					args: [
						!vuexCtx.state.settings.general.enable_token_number_system || vuexCtx.state.device.is_primary
							? null
							: vuexCtx.state.primaryDevice?.ip
					]
				})

				response = typeof response === 'string' ? JSON.parse(response) : response
				requestAnimationFrame(() => vuexCtx.commit('resetLoading'))

				if (!response) {
					return null
				}

				orderCount = +response.orderCount
			} else {
				orderCount = +this.$bridge.getLocalStorage('orderCount')

				if (+this.$bridge.getLocalStorage('lastOrderDay') !== moment.toDate().getDate()) {
					orderCount = 1
				} else {
					orderCount += 1
				}
			}

			return `${vuexCtx.state.locationId}${vuexCtx.state.deviceId}${vuexCtx.state.employee.id}${moment.format('YYMMDDHHmmss')}${orderCount.toString().padStart(4, '0')}`
		}
	},
	async generateRefCode (vuexCtx) {
		if (typeof this.$bridge.generateRefCode === 'function') {
			if (vuexCtx.state.settings.general.enable_token_number_system && !vuexCtx.state.device.is_primary && !vuexCtx.state.primaryDevice) {
				vuexCtx.commit('setLoading', {
					show: true,
					text: 'connecting to primary device'
				})
			}

			const response = await vuexCtx.dispatch('bridgeCall', {
				methodName: 'generateRefCode',
				args: []
			})

			requestAnimationFrame(() => vuexCtx.commit('resetLoading'))

			return typeof response === 'string' ? JSON.parse(response) : response
		} else {
			const date = this.$moment()
			const refCode = JSON.parse(await this.$bridge.getLocalStorage('refCode') || '{}')
			const prefix = `${
				vuexCtx.state.device?.ref_code_prefix ||
				vuexCtx.state.location.customAttributes?.order_seq_prefix ||
				vuexCtx.state.locationId
			}${
				!vuexCtx.state.device?.ref_code_prefix ? vuexCtx.state.deviceId : ''
			}${date.format('DDMMYY')}`
			let refCodeCount = +refCode.count || 0
			const time = vuexCtx.state.settings.general.order_sequential_reset_time || 'year'
			let increaseCount = time !== 'year' ? this.$moment(refCode.date).isSame(date, time) : true
			const resetMonth = vuexCtx.state.settings.general.order_sequential_reset_month || 1

			if (time === 'year' && (new Date(date.year() + '-' + resetMonth + '-' + 1) > new Date(refCode.date)) && +resetMonth === (date.month() + 1)) {
				increaseCount = false
			}

			if (refCode.date && increaseCount) {
				refCodeCount += 1
			} else {
				refCodeCount = 1
			}

			return {
				code: `${prefix}${
					refCodeCount.toString().padStart(vuexCtx.state.settings.general.invoice_counter || 4, '0')
				}`,
				prefix
			}
		}
	},
	async updateLastOrder (vuexCtx, { orderCount, refCode, date }) {
		if (typeof this.$bridge.generateReceiptCode === 'undefined') {
			if (vuexCtx.getters.appVersionNumber < 3842 || (vuexCtx.state.bridgeName === 'ANDROID' && vuexCtx.getters.appVersionNumber < 3853)) {
				await this.$bridge.setLocalStorage('lastOrderDay', date.getDate())
				await this.$bridge.setLocalStorage('orderCount', +orderCount)
			}

			if (refCode) {
				await this.$bridge.setLocalStorage('refCode', this.app.objToJson({
					date,
					count: refCode
				}))
			}
		}
	},
	createRefund (vuexCtx, refund) {
		return vuexCtx.state.location.platforms?.includes('zatca') && !vuexCtx.getters.isTestingDevice &&
			vuexCtx.state.merchant.subscription.slug !== 'mini'
			? this.$axios.post('/api/pos/sync/order-refund', refund)
			: Promise.resolve()
	},
	updateOnlineOrderStatus (vuexCtx, { orderId, data }) {
		return this.$axios.patch(`/api/online-orders/${orderId}`, data)
	},
	updateOnlineOrder (vuexCtx, { orderId, data }) {
		return this.$axios.put(`/api/online-orders/${orderId}`, data)
	},
	getAdvanceOrdersCount (vuexCtx) {
		this.$axios.get('/api/online-orders/state-aggregate', {
			params: {
				location_id: vuexCtx.state.locationId,
				platform: 'advance',
				from_date: this.$moment().startOf('day').utc().format('DD-MM-YYYY HH:mm:ss'),
				to_date: this.$moment().endOf('day').utc().format('DD-MM-YYYY HH:mm:ss')
			}
		}).then((response) => {
			vuexCtx.commit('setState', {
				key: 'advanceOrdersCount',
				value: response.data.data.order_state_aggregate.find(s => s.state === 'placed')?.count || 0
			})
		}).catch(console.error)
	},
	checkForUpdates (ctx) {
		if (
			this.$offline.state === 'up' &&
			(!ctx.state.isLoggedIn || !ctx.state.employeeShift?.shift_code) &&
			(
				[0, null, undefined].includes(ctx.state.partner?.beta_testing?.complete?.length) ||
				!ctx.state.merchant ||
				ctx.state.partner.beta_testing.complete.findIndex(m => m.id === ctx.state.merchant.id) > -1
			)
		) {
			this.$bridge.checkForUpdates()
		}
	},
	async updateDeviceSettings () {
		const settings = {}
		const orderCount = +(await this.$bridge.getLocalStorage('orderCount'))
		const refCode = JSON.parse(await this.$bridge.getLocalStorage('refCode'))
		const lastForceSyncDate = await this.$bridge.getLocalStorage('lastForceSyncDate')
		const nextBussinessOpeningTime = await this.$bridge.getLocalStorage('nextBussinessOpeningTime')

		if (refCode) {
			settings.refCode = refCode
		}

		if (orderCount) {
			settings.orderCount = orderCount
		}

		if (lastForceSyncDate) {
			settings.lastForceSyncDate = lastForceSyncDate
		}

		if (nextBussinessOpeningTime) {
			settings.nextBussinessOpeningTime = nextBussinessOpeningTime
		}

		return this.$axios.patch('/api/pos/device-settings', { settings })
	},
	async getOrderDetails (vuexCtx, orderId) {
		try {
			const response = await this.$axios.$get(`/api/pos/order/${orderId}`)

			return response.data.order
		} catch (err) {
			console.error(err)

			return null
		}
	},
	getOrders (vuexContext, data) {
		return this.$axios.$get('/api/pos/orders', {
			params: data
		})
	},
	getPlaces (vuexContext, data) {
		return this.$axios.$get('/api/maps/places', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				components: null
			}, data)
		})
	},
	getPlace (vuexContext, data) {
		return this.$axios.$get('/api/maps/place', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				fields: 'formatted_address,address_component,geometry',
				save: data.save
			}, data)
		})
	},
	getPlaceByGeocode (vuexContext, data) {
		return this.$axios.$get('/api/maps/geocode', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				save: data.save
			}, data)
		})
	},
	getDistance (vuexContext, data) {
		return this.$axios.$get('/api/maps/distance', {
			params: Object.assign({
				key: process.env.GOOGLE_MAPS_API_KEY,
				language: null,
				region: null
			}, data)
		})
	},
	storeVerifyPhone (vuexContext, data) {
		return this.$axios.$post('/api/store-orders/verify-phone', data, {
			headers: {
				'x-api-merchant': vuexContext.state.merchant.mCode
			}
		})
	},
	storeBasicRegister (vuexContext, data) {
		return this.$axios.$post('/api/store-orders/basic-register', data, {
			headers: {
				'x-api-merchant': vuexContext.state.merchant.mCode
			}
		})
	},
	async updateTableStatus (vuexContext, tableIds = []) {
		for await (const id of tableIds) {
			let updateTable = null
			let tableOrders = await vuexContext.dispatch('bridgeCall', {
				methodName: 'getOrders',
				args: [vuexContext.state.deviceId, this.app.objToJson({
					table_id: id,
					status: ['pending', 'billed']
				})]
			})

			tableOrders = (vuexContext.state.bridgeName === 'ANDROID' ? JSON.parse(tableOrders).data : tableOrders.data) || []
			updateTable = {
				id,
				is_occupied: tableOrders.length >= 1,
				status: tableOrders.length >= 1
					? tableOrders.filter((o) => {
						return o.status !== 'billed'
					}).length >= 1
						? 'occupied'
						: 'billed'
					: 'free'
			}

			await vuexContext.dispatch('bridgeCall', {
				methodName: 'insert',
				args: [
					'Table',
					vuexContext.state.bridgeName === 'ANDROID' ? this.app.objToJson(updateTable) : updateTable,
					true
				]
			})
		}
	},
	async getGiftCardsDetails (vuexContext, data) {
		try {
			const response = await this.$axios.get('/api/pos-sync/discount/gift-card', {
				params: data
			})

			return response.data.data.discounts
		} catch (err) {
			console.error(err)

			return null
		}
	},
	validateGiftCard (vuexContext, data) {
		return this.$axios.post('/api/pos/gift-card/validate', data)
	},
	async bridgeCall (vuexContext, data) {
		if (
			vuexContext.state.settings.general.enable_token_number_system &&
			!vuexContext.state.device.is_primary &&
			!vuexContext.state.primaryDevice
		) {
			await vuexContext.dispatch('getPrimaryDevice', true)

			if (!vuexContext.state.primaryDevice) {
				throw new Error('Could not connect to the primary device')
			}
		}

		if (
			typeof this.$bridge.bridgeCall === 'function' &&
			vuexContext.state.settings.general.enable_token_number_system &&
			!vuexContext.state.device.is_primary
		) {
			const name = await this.$bridge.bridgeCall('getName', ...[])

			if (!name) {
				vuexContext.commit('setState', {
					key: 'primaryDevice',
					value: null,
					save: true
				})
				await vuexContext.dispatch('getPrimaryDevice', true)

				if (!vuexContext.state.primaryDevice) {
					throw new Error('Could not connect to the primary device')
				}
			}

			return await this.$bridge.bridgeCall(data.methodName, ...data.args)
		}

		return await this.$bridge[data.methodName](...data.args)
	},
	translate (vuexContext, data) {
		return this.$axios.$post('/api/openai/translate', data)
	}
}

export default actions
