export default (app) => {
	const getSyncOptions = async (model, options = {}) => {
		const deviceId = app.store.state.deviceId
		const locationId = app.store.state.locationId

		let updatedAt = null

		switch (model) {
			case 'merchant-details':
			case 'merchants':
			case 'merchant_locations':
				Object.assign(options, {
					model: 'merchant-details',
					name: app.i18n.t('merchant details'),
					deviceId,
					locationId,
					params: {
						location_id: locationId,
						device_id: deviceId
					}
				})

				break
			case 'devices':
			case 'merchant_devices':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Device', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'devices',
					collection: 'Device',
					name: app.i18n.tc('device', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.devices?.lastSynced
							? app.$moment(app.store.state.syncDetails.devices?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'floors':
			case 'merchant_floors':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Floor', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'floors',
					collection: 'Floor',
					name: app.i18n.tc('floor', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.floors?.lastSynced
							? app.$moment(app.store.state.syncDetails.floors?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'floor-tables':
			case 'merchant_floor_tables':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Table', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'floor-tables',
					collection: 'Table',
					name: app.i18n.tc('table', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['floor-tables']?.lastSynced
							? app.$moment(app.store.state.syncDetails['floor-tables']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'employees':
			case 'merchant_employees':
			case 'merchant_employee_roles':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Employee', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'employees',
					collection: 'Employee',
					name: app.i18n.tc('employee', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.employees?.lastSynced
							? app.$moment(app.store.state.syncDetails.employees?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'merchant-customer-groups':
			case 'merchant_customer_groups':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('CustomerGroup', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'merchant-customer-groups',
					collection: 'CustomerGroup',
					name: app.i18n.tc('customer groups', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['merchant-customer-groups']?.lastSynced
							? app.$moment(app.store.state.syncDetails['merchant-customer-groups']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'merchant-customers':
			case 'merchant_customers':
			case 'merchant_customer_credits':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Customer', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'merchant-customers',
					collection: 'Customer',
					name: app.i18n.tc('customer', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['merchant-customers']?.lastSynced
							? app.$moment(app.store.state.syncDetails['merchant-customers']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'categories':
			case 'item_categories':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Category', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'categories',
					collection: 'Category',
					name: app.i18n.tc('category', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.categories?.lastSynced
							? app.$moment(app.store.state.syncDetails.categories?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'merchant-price-categories':
			case 'merchant_price_categories':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('PriceCategory', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'merchant-price-categories',
					collection: 'PriceCategory',
					name: app.i18n.tc('price category', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['merchant-price-categories']?.lastSynced
							? app.$moment(app.store.state.syncDetails['merchant-price-categories']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'taxes':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Tax', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'taxes',
					collection: 'Tax',
					name: app.i18n.tc('tax', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.taxes?.lastSynced
							? app.$moment(app.store.state.syncDetails.taxes?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'discounts':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Discount', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'discounts',
					collection: 'Discount',
					name: app.i18n.tc('discount', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.discounts?.lastSynced
							? app.$moment(app.store.state.syncDetails.discounts?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'merchant-charges':
			case 'merchant_charges':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Charge', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'merchant-charges',
					collection: 'Charge',
					name: app.i18n.tc('charge', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['merchant-charges']?.lastSynced
							? app.$moment(app.store.state.syncDetails['merchant-charges']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'items':
			case 'item_variations':
			case 'item_inventories':
			case 'item_inventory_stocks':
			case 'item_inventory_prices':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Item', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'items',
					collection: 'Item',
					name: app.i18n.tc('item', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails.items?.lastSynced
							? app.$moment(app.store.state.syncDetails.items?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true,
						include_items_with_no_inventory: true
					}
				})

				break
			case 'item-variation-groups':
			case 'item_variation_groups':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('ItemVariationGroup', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'item-variation-groups',
					collection: 'ItemVariationGroup',
					name: app.i18n.t('item variation groups'),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						location_id: locationId,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['item-variation-groups']?.lastSynced
							? app.$moment(app.store.state.syncDetails['item-variation-groups']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'merchant-payment-methods':
			case 'merchant_payment_methods':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('PaymentMethod', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'merchant-payment-methods',
					collection: 'PaymentMethod',
					name: app.i18n.tc('payment method', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['merchant-payment-methods']?.lastSynced
							? app.$moment(app.store.state.syncDetails['merchant-payment-methods']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			case 'item-brands':
				if (!options.force) {
					updatedAt = await app.$bridge.getLastUpdatedAt('Brand', deviceId)
					updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				}

				Object.assign(options, {
					model: 'item-brands',
					collection: 'Brand',
					name: app.i18n.tc('brand', 2),
					deviceId,
					locationId,
					params: {
						all_merchant: true,
						updated_at: app.getUpdatedAtDateTime(updatedAt?.updated_at),
						last_synced_at: app.store.state.syncDetails['item-brands']?.lastSynced
							? app.$moment(app.store.state.syncDetails['item-brands']?.lastSynced).utc().format('YYYY-MM-DD HH:mm:ss')
							: null,
						trash: true
					}
				})

				break
			default:
				options = null

				break
		}

		return options
	}

	const sync = ({ model, collection, name, deviceId, locationId, params, silent, page = 1 }) => {
		if (app.$offline.state === 'up') {
			if (!silent && page === 1) {
				app.store.commit('setProgress', { name, value: 0, show: true })
			}

			return new Promise((resolve, reject) => {
				app.$axios.get(`/api/pos-sync/sync/${model}`, {
					params: Object.assign({}, {
						page,
						limit: 100
					}, params)
				}).then(async (response) => {
					app.store.commit('updateSyncDetails', { key: model, value: { lastSynced: new Date() } })

					if (collection) {
						const modelCamelCase = app.convertCase('kebab', 'camel', model)

						await app.$bridge.customInsertOrUpdate(
							collection, deviceId, locationId, app.store.state.bridgeName === 'ANDROID'
								? app.objToJson(response.data.data[modelCamelCase])
								: response.data.data[modelCamelCase]
						)

						if (model === 'merchant-customers' && document.querySelector('.main-menu .nav-link.active')?.getAttribute('data-key') === 'customers') {
							window.refreshTab('customers')
						}

						if (model === 'employees') {
							let employees = await app.$bridge.getEmployees(deviceId, locationId, app.objToJson({
								type: ['admin', 'staff'],
								offset: -1
							}))

							employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
							employees = employees.data || employees

							app.store.commit('setState', {
								key: 'employees',
								value: employees
							})

							if (app.store.state.employee) {
								app.store.commit('setState', {
									key: 'employee',
									value: {
										...app.store.state.employee,
										...employees.find(e => e.id === app.store.state.employee.id)
									},
									save: true
								})
							}
						}

						const progressValue = page < response.data.data.pagination.lastPage ? Math.floor((page / response.data.data.pagination.lastPage) * 100) : 100

						if (!silent) {
							app.store.commit('setProgress', { value: progressValue })
						}

						if (page < response.data.data.pagination.lastPage) {
							return sync({
								model,
								collection,
								name,
								deviceId,
								locationId,
								params,
								silent,
								page: ++page
							}).then(() => resolve())
						} else {
							setTimeout(resolve, 1000)
						}
					} else if (model === 'merchant-details') {
						let merchantLogos = []

						merchantLogos.push({
							id: response.data.data.merchant.id,
							logoUrl: response.data.data.merchant.logoUrl,
							digitalSign: response.data.data.merchant.settings?.invoice?.digital_sign,
							digitalStamp: response.data.data.merchant.settings?.invoice?.digital_stamp,
							letterHeadUrl: response.data.data.merchant.customAttributes.letter_head_img
						})

						if (response.data.data.merchant.childMerchants && response.data.data.merchant.childMerchants.length) {
							merchantLogos = merchantLogos.concat(response.data.data.merchant.childMerchants.reduce((arr, m) => {
								if (m.logoUrl) {
									arr.push({
										id: m.id,
										logoUrl: m.logoUrl,
										digitalSign: m.settings?.invoice?.digital_sign,
										digitalStamp: m.settings?.invoice?.digital_stamp,
										letterHeadUrl: m.customAttributes.letter_head_img
									})
								}

								return arr
							}, []))
						}

						for (let i = 0; i < merchantLogos.length; i++) {
							if (merchantLogos[i].logoUrl) {
								app.getDataURL(merchantLogos[i].logoUrl)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}Logo`, dataURL))
									.catch(console.error)
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}Logo`)
							}

							if (merchantLogos[i].digitalSign) {
								app.getDataURL(merchantLogos[i].digitalSign)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}digitalSign`, dataURL))
									.catch(console.error)
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}digitalSign`)
							}

							if (merchantLogos[i].digitalStamp) {
								app.getDataURL(merchantLogos[i].digitalStamp)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}digitalStamp`, dataURL))
									.catch(console.error)
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}digitalStamp`)
							}

							if (merchantLogos[i].letterHeadUrl) {
								app.getDataURL(merchantLogos[i].letterHeadUrl)
									.then(dataURL => app.$bridge.setLocalStorage(`merchant${merchantLogos[i].id}LetterHeadImage`, dataURL))
									.catch(console.error)
							} else {
								app.$bridge.removeLocalStorage(`merchant${merchantLogos[i].id}LetterHeadImage`)
							}
						}

						const refCode = await app.$bridge.getLocalStorage('refCode')

						if (!refCode) {
							if (response.data.data.device.settings.refCode) {
								await app.$bridge.setLocalStorage('refCode', app.objToJson(response.data.data.device.settings.refCode))
							}

							if (response.data.data.device.settings.orderCount) {
								await app.$bridge.setLocalStorage('orderCount', response.data.data.device.settings.orderCount)
							}

							if (response.data.data.device.settings.lastForceSyncDate) {
								await app.$bridge.setLocalStorage('lastForceSyncDate', response.data.data.device.settings.lastForceSyncDate)
							}

							if (response.data.data.device.settings.nextBussinessOpeningTime) {
								await app.$bridge.setLocalStorage('nextBussinessOpeningTime', response.data.data.device.settings.nextBussinessOpeningTime)
							}
						}

						if (response.data.data.merchant.settings.receipt.preview && app.store.state.showOrderConfirmation) {
							app.store.commit('setState', {
								key: 'showOrderConfirmation',
								value: false,
								save: true
							})
						}

						app.store.commit('setSettings', response.data.data.merchant.settings)
						delete response.data.data.merchant.settings
						app.store.commit('setState', {
							key: 'merchant',
							value: response.data.data.merchant,
							save: true
						})

						app.store.dispatch('checkSubscription')
						app.store.commit('setState', {
							key: 'location',
							value: response.data.data.location,
							save: true
						})
						app.store.commit('setState', {
							key: 'device',
							value: response.data.data.device,
							save: true
						})

						if (response.data.data.location.counters && response.data.data.location.counters.permit_no) {
							const syncData = app.$bridge.getSyncData()

							if (!syncData) {
								app.$bridge.setLocalStorage('permitNumber', response.data.data.location.counters.permit_no)
							}
						}

						if (!silent) {
							app.store.commit('setProgress', { value: 100 })
						}

						setTimeout(resolve, 1000)
					}
				}).catch((err) => {
					if (!silent) {
						app.store.commit('setProgress', { value: 100 })
					}

					reject(err)
				})
			})
		} else {
			app.$swal({
				title: app.i18n.t('offlineError.title'),
				text: app.i18n.t('offlineError.text'),
				icon: 'error',
				button: app.i18n.t('ok')
			})

			return Promise.resolve()
		}
	}

	const syncAll = async (options = {}) => {
		try {
			await sync(await getSyncOptions('merchant-details', options))
			await sync(await getSyncOptions('employees', options))

			if (['restaurant', 'qsr'].includes(app.store.state.merchant.businessType)) {
				await sync(await getSyncOptions('devices', options))
				await sync(await getSyncOptions('floors', options))
				await sync(await getSyncOptions('floor-tables', options))
			}

			await sync(await getSyncOptions('merchant-customer-groups', options))
			await sync(await getSyncOptions('merchant-customers', options))
			await sync(await getSyncOptions('categories', options))
			await sync(await getSyncOptions('item-brands', options))
			await sync(await getSyncOptions('merchant-price-categories', options))
			await sync(await getSyncOptions('taxes', options))
			await sync(await getSyncOptions('discounts', options))
			await sync(await getSyncOptions('merchant-charges', options))
			await sync(await getSyncOptions('items', options))
			await sync(await getSyncOptions('item-variation-groups', options))
			await sync(await getSyncOptions('merchant-payment-methods', options))
		} catch (err) {
			console.error(err)
		}
	}

	const preSyncProcess = async (syncData) => {
		const deviceId = +(await app.$bridge.getLocalStorage('deviceId'))

		switch (syncData.model_name) {
			case 'order': {
				let order = await app.store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [deviceId, app.objToJson({ id: syncData.payload.orders[0].id })]
				})

				order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

				if (order) {
					if (order.custom_attributes.is_custom_refund) {
						syncData.payload.orders[0].sub_total = 0
						syncData.payload.orders[0].total_tax = 0
						syncData.payload.orders[0].taxes = syncData.payload.orders[0].taxes.map(t => ({
							...t,
							tax_amount: 0
						}))
						syncData.payload.orders[0].total_charge = 0
						syncData.payload.orders[0].charges = syncData.payload.orders[0].charges?.map(c => ({
							...c,
							amount: 0,
							tax: 0,
							taxes: c.taxes.map(t => ({
								...t,
								tax_amount: 0
							}))
						}))
						syncData.payload.orders[0].total_price = 0
						syncData.payload.orders[0].total_discount = 0
						syncData.payload.orders[0].total_discounted_amount = 0
						syncData.payload.orders[0].total_discounted_tax = 0
						syncData.payload.orders[0].amount_balance_returned = 0
						syncData.payload.orders[0].total_amount = 0
						syncData.payload.orders[0].due_amount = 0
						syncData.payload.orders[0].tip = 0
						syncData.payload.orders[0].round_off = 0
						syncData.payload.orders[0].order_payments = syncData.payload.orders[0].order_payments.map(p => ({
							...p,
							amount: 0
						}))
						syncData.payload.orders[0].items = syncData.payload.orders[0].items.map(i => ({
							...i,
							quantity: 0,
							sub_total: 0,
							total: 0,
							gross_sales: 0,
							net_sales: 0,
							tax: 0,
							tax_details: i.tax_details.map(t => ({
								...t,
								tax_amount: 0
							})),
							discount: 0,
							discounted_amount: 0,
							discounted_tax: 0,
							discount_details: i.discount_details.map(d => ({
								...d,
								discounted_amount: 0,
								discounted_tax: 0
							}))
						}))
					}

					if (order.order_id) {
						syncData.payload.orders[0].order_id = order.order_id
					}

					syncData.payload.orders[0].created_at = app.$moment.utc(order.created_at).format('YYYY-MM-DD HH:mm:ss')
				}

				break
			}

			case 'cash-drawer-shift-event': {
				let cashDrawerShift = await app.store.dispatch('bridgeCall', {
					methodName: 'getCashDrawerShifts',
					args: [deviceId, app.objToJson({ id: syncData.payload.cash_drawer_shift_id })]
				})

				cashDrawerShift = (typeof cashDrawerShift === 'string'
					? JSON.parse(cashDrawerShift)
					: cashDrawerShift).data[0]

				if (cashDrawerShift) {
					syncData.payload.cash_drawer_shift_id = cashDrawerShift.cash_drawer_shift_id
				}

				break
			}

			case 'order-refund': {
				let order = await app.store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [deviceId, app.objToJson({ id: syncData.payload.order_id })]
				})

				order = (typeof order === 'string' ? JSON.parse(order) : order).data[0]

				if (order) {
					syncData.payload.order_id = order.order_id
				}

				break
			}

			case 'customer-credit': {
				if (syncData.payload.customer_id) {
					let customer = await app.store.dispatch('bridgeCall', {
						methodName: 'getCustomers',
						args: [deviceId, app.objToJson({ id: syncData.payload.customer_id })]
					})

					customer = (typeof customer === 'string' ? JSON.parse(customer) : customer)
						.data[0]

					if (customer) {
						syncData.payload.merchant_customer_id = customer.customer_id
					}
				}

				break
			}

			default:
				break
		}

		return syncData
	}

	const postSyncProcess = async (modelId, modelName, result) => {
		const date = new Date()
		const deviceId = +(await app.$bridge.getLocalStorage('deviceId'))

		switch (modelName) {
			case 'employee-shift': {
				let employeeShift = await app.store.dispatch('bridgeCall', {
					methodName: 'getEmployeeShifts',
					args: [deviceId, app.objToJson({ id: modelId })]
				})

				employeeShift = typeof employeeShift === 'string' ? JSON.parse(employeeShift) : employeeShift
				employeeShift = employeeShift ? employeeShift[0] : null

				if (employeeShift) {
					employeeShift = Object.assign({}, employeeShift, {
						updated_at: date,
						is_synced: !!employeeShift.clock_out
					})
					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'EmployeeShift',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(employeeShift) : employeeShift,
							true
						]
					})
				}

				break
			}

			case 'cash-drawer-shift': {
				let cashDrawerShift = await app.store.dispatch('bridgeCall', {
					methodName: 'getCashDrawerShifts',
					args: [deviceId, app.objToJson({ id: modelId })]
				})

				cashDrawerShift = (
					typeof cashDrawerShift === 'string' ? JSON.parse(cashDrawerShift) : cashDrawerShift
				).data[0]

				if (cashDrawerShift) {
					cashDrawerShift = {
						id: cashDrawerShift.id,
						cash_drawer_shift_id: app.getUniqueId(result.cashDrawerShift.id),
						updated_at: date,
						is_synced: !!cashDrawerShift.closed_at
					}
					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'CashDrawerShift',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(cashDrawerShift) : cashDrawerShift,
							true
						]
					})
				}

				break
			}

			case 'order': {
				let dbOrder = await app.store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [deviceId, app.objToJson({ id: modelId })]
				})

				dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

				if (dbOrder) {
					const order = {
						id: dbOrder.id,
						order_id: app.getUniqueId(result.order?.id || result.orders?.pos_order_id),
						updated_at: date,
						is_synced: true
					}

					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Order',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(order) : order,
							true
						]
					})

					if (result.order?.customers?.length) {
						let customer = await app.store.dispatch('bridgeCall', {
							methodName: 'getCustomers',
							args: [
								deviceId,
								app.objToJson({ id: parseInt(result.order.customers[0].customer.phone) })
							]
						})

						customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

						if (customer && !customer.customer_id) {
							customer = {
								id: customer.id,
								customer_id: result.order.customers[0].customer.id,
								updated_at: date
							}
							await app.store.dispatch('bridgeCall', {
								methodName: 'insert',
								args: [
									'Customer',
									app.store.state.bridgeName === 'ANDROID' ? app.objToJson(customer) : customer,
									true
								]
							})
						}
					}

					if (dbOrder.custom_attributes.platform === 'store' && dbOrder.custom_attributes.order_type === 'delivery' && result.orders.pos_order_id && typeof app.$bridge.updateId === 'function') {
						await app.store.dispatch('bridgeCall', {
							methodName: 'updateId',
							args: ['Order', dbOrder.id, result.orders.pos_order_id.toString()]
						})
					}
				}

				break
			}

			case 'cash-drawer-shift-event': {
				let cashDrawerShiftEvent = app.store.getters.appVersionNumber < 3839
					? await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['CashDrawerShiftEvent', deviceId, app.objToJson([
							{ key: 'id', value: modelId }
						])]
					})
					: await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['CashDrawerShiftEvent', app.objToJson([
							{ key: 'id', value: modelId }
						])]
					})

				cashDrawerShiftEvent = typeof cashDrawerShiftEvent === 'string'
					? JSON.parse(cashDrawerShiftEvent)
					: cashDrawerShiftEvent

				if (cashDrawerShiftEvent) {
					cashDrawerShiftEvent = {
						id: cashDrawerShiftEvent.id,
						updated_at: date,
						is_synced: true
					}
					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'CashDrawerShiftEvent',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(cashDrawerShiftEvent) : cashDrawerShiftEvent,
							true
						]
					})
				}

				break
			}

			case 'customer-credit': {
				let credit = app.store.getters.appVersionNumber < 3839
					? await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['Credit', deviceId, app.objToJson([
							{ key: 'id', value: modelId }
						])]
					})
					: await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['Credit', app.objToJson([
							{ key: 'id', value: modelId },
							{ key: 'device_id', value: deviceId }
						])]
					})

				credit = typeof credit === 'string' ? JSON.parse(credit) : credit

				if (credit) {
					credit = {
						id: credit.id,
						updated_at: date,
						is_synced: true
					}
					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Credit',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(credit) : credit,
							true
						]
					})
				}

				let employeeShift = await app.store.dispatch('bridgeCall', {
					methodName: 'getEmployeeShifts',
					args: [deviceId, app.objToJson({
						shift_code: result.customerCredit.employeeShiftCode
					})]
				})

				employeeShift = typeof employeeShift === 'string' ? JSON.parse(employeeShift) : employeeShift
				employeeShift = employeeShift ? employeeShift[0] : null

				const updateCredit = async (creditOrder) => {
					let customer = await app.store.dispatch('bridgeCall', {
						methodName: 'getCustomers',
						args: [deviceId, app.objToJson({
							customer_id: result.customerCredit.merchantCustomerId
						})]
					})

					customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

					if (customer?.customer_id === result.customerCredit.merchantCustomerId) {
						const credit = {
							id: app.getUniqueId(),
							merchant_id: app.store.state.merchant.id,
							device_id: deviceId,
							employee_id: employeeShift.employee_id,
							employee_shift_id: employeeShift.id,
							customer_id: customer.id,
							order_id: null,
							credit_code: `${deviceId}${new Date().valueOf()}`,
							amount: creditOrder?.payable_amount || result.customerCredit.amount,
							payment_method: result.customerCredit.paymentMethod,
							type: 'debit',
							notes: '',
							custom_attributes: app.objToJson({
								source: 'complete'
							}),
							is_synced: true,
							created_at: date,
							updated_at: date
						}

						await app.store.dispatch('bridgeCall', {
							methodName: 'insert',
							args: [
								'Credit',
								app.store.state.bridgeName === 'ANDROID' ? app.objToJson(credit) : credit,
								true
							]
						})
					}
				}

				if (employeeShift) {
					if (result.customerCredit.orders.length) {
						for await (const creditOrder of result.customerCredit.orders) {
							let dbOrder = await app.store.dispatch('bridgeCall', {
								methodName: 'getOrders',
								args: [deviceId, app.objToJson({ order_id: creditOrder.order_id.toString() })]
							})

							dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

							if (dbOrder) {
								dbOrder.credits = dbOrder.credits.map((c) => {
									return {
										...c,
										employee_id: c.employee_shift?.employee_id || null,
										employee_shift_id: c.employee_shift?.id || null,
										custom_attributes: app.objToJson(c.custom_attributes),
										payment_method: c.payment_method.slug,
										customer_id: c.customer.id
									}
								})
								const credit = {
									id: app.getUniqueId(),
									merchant_id: app.store.state.merchant.id,
									device_id: deviceId,
									employee_id: employeeShift.employee_id,
									employee_shift_id: employeeShift.id,
									customer_id: dbOrder.customer.id,
									order_id: dbOrder.id,
									credit_code: `${deviceId}${new Date().valueOf()}`,
									amount: creditOrder.payable_amount,
									payment_method: result.customerCredit.paymentMethod,
									type: 'debit',
									notes: '',
									custom_attributes: app.objToJson({
										source: 'complete'
									}),
									is_synced: true,
									created_at: date,
									updated_at: date
								}

								const order = {
									id: dbOrder.id,
									credits: dbOrder.credits.concat(credit),
									// due_amount: result.customerCredit.order.due_amount,
									refresh: false
								}

								await app.store.dispatch('bridgeCall', {
									methodName: 'insert',
									args: [
										'Order',
										app.store.state.bridgeName === 'ANDROID' ? app.objToJson(order) : order,
										true
									]
								})
							} else {
								updateCredit(creditOrder)
							}
						}
					} else if (!result.customerCredit.orderId) {
						updateCredit()
					}
				}

				break
			}

			case 'order-refund': {
				let refund = app.store.getters.appVersionNumber < 3839
					? await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['OrderRefund', deviceId, app.objToJson([
							{ key: 'id', value: modelId }
						])]
					})
					: await app.store.dispatch('bridgeCall', {
						methodName: 'getRecord',
						args: ['OrderRefund', app.objToJson([
							{ key: 'id', value: modelId }
						])]
					})

				refund = typeof refund === 'string' ? JSON.parse(refund) : refund

				if (refund) {
					refund = {
						id: refund.id,
						is_synced: true,
						updated_at: date
					}
					await app.store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'OrderRefund',
							app.store.state.bridgeName === 'ANDROID' ? app.objToJson(refund) : refund,
							true
						]
					})
				}

				break
			}

			default:
				break
		}
	}

	const backgroundSync = async () => {
		while (true) {
			if (
				app.$offline.state === 'up' &&
				app.store.state.isLoggedIn &&
				!app.store.getters.isTestingDevice &&
				app.store.state.merchant.subscription.slug !== 'mini'
			) {
				let syncData = await app.$bridge.getSyncData()

				syncData = typeof syncData === 'string' ? JSON.parse(syncData) : syncData

				if (syncData) {
					if (Object.keys(syncData.payload).length === 0) {
						try {
							await sync(await getSyncOptions(syncData.model_name, { silent: true }))
						} catch (err) {
							console.error(err)
						} finally {
							await app.$bridge.deleteRecord('Sync', syncData.id)
						}
					} else if (syncData.attempts < 3) {
						try {
							syncData = await preSyncProcess(syncData)

							const response = await app.$axios.post(`/api/pos/sync/${syncData.model_name}`, syncData.payload)

							app.store.commit('updateSyncDetails', { key: syncData.model_name, value: { lastSynced: new Date() } })
							await postSyncProcess(syncData.model_id, syncData.model_name, response.data.data)
							await app.$bridge.deleteRecord('Sync', syncData.id)
						} catch (err) {
							console.error(err)

							if (err.response) {
								const data = {
									id: syncData.id,
									attempts: syncData.attempts + 1
								}

								await app.$bridge.insert(
									'Sync',
									app.store.state.bridgeName === 'ANDROID' ? app.objToJson(data) : data,
									true
								)
							}
						}
					} else {
						try {
							const preSyncResponse = await preSyncProcess(syncData)

							await app.$axios.post('/api/pos/sync/failed', {
								model_id: preSyncResponse.model_id,
								model_name: preSyncResponse.model_name,
								payload: preSyncResponse.payload
							})
							await app.$bridge.deleteRecord('Sync', syncData.id)
						} catch (err) {
							console.error(err)
						}
					}
				}
			}

			await app.sleep(3000)
		}
	}

	const repairSyncQueueCheck = async () => {
		while (true) {
			if (
				app.store.state.settings.general.auto_repair_sync_queue &&
				app.$offline.state === 'up' &&
				app.store.state.isLoggedIn &&
				!app.store.getters.isTestingDevice &&
				app.store.state.merchant.subscription.slug !== 'mini'
			) {
				let syncDetails = await app.$bridge.getSyncDetails(
					app.store.state.merchant.id, app.store.state.locationId, app.store.state.deviceId
				)
				let syncData = await app.$bridge.getSyncData()

				syncDetails = typeof syncDetails === 'string' ? JSON.parse(syncDetails) : syncDetails
				syncData = typeof syncData === 'string' ? JSON.parse(syncData) : syncData

				if (syncDetails.order.total !== syncDetails.order.synced && !syncData) {
					await app.$bridge.repairSyncQueue()
				}
			}

			await app.sleep(300000)
		}
	}

	return {
		getSyncOptions,
		sync,
		syncAll,
		backgroundSync,
		repairSyncQueueCheck
	}
}
